/* eslint-disable react-hooks/exhaustive-deps */
import { IconList } from "assets/image";
import { BaseModal, ContentHeader } from "components";
import _ from "lodash";
import FilterMasterDataExpense from "./filter/filter";
import React, {useState} from "react";
import VehicleCalculationTable from "components/Molecules/Table/vehicleCalculationTable";
import { downloadVehicleCalculation, getListVehicleCalculation } from "module/reports/vehicleCalculation/action";
import toastHook from "helper/Hook/toastr";

const renderTableValue = (row, record) => {
  return !row.children ? (
    <div className={`${record.className} ${row.className}`} style={{backgroundColor: row.bgColor}}>
      {row.value}
    </div>
  ) : (
    <div className={`${row.className} justify-between`}>
      {row.children.map((item, index) => {
        return (
          <div className={`${item.className}`} style={{backgroundColor: item.bgColor}} key={index+"child"}>{item.label}</div>
        )
      })}
    </div>
  )
}

const WarehouseCalculation = () => {
  const [originData, setOriginData] = useState([])
  const [childData, setChildData] = useState([])
  const [isExpand, setIsExpand] = useState(false);
  const [isOpenModalFilter, setModalFilter] = useState(false)
  const [isOpenModalCancel, setCancelModal] = useState(false) 
  const [loadingTable, setLoadingTable] = useState(false)
  const [filter, setFilter] = useState({
    company_id: "",
    year: new Date(),
    plant_id: "",
    // start_month: new Date().setMonth(0),
    // end_month: new Date().setMonth(11)
  })

  // get EmployeeExpense list
  React.useEffect(() => {
    // getWarehouseCalculation()
    if(filter.company_id !== "") {
      getWarehouseCalculation()
    }
  }, [filter])

  const getWarehouseCalculation = (type) => {
    setLoadingTable(true)
    getListVehicleCalculation(filter, type)
    .then(result => {
      setLoadingTable(false)
      setOriginData(result.mainData)
      setChildData(result.childData)
      setModalFilter(false)
    })
  }

  const handleFilterData = (data) => {
    let cloneFilter = _.cloneDeep(filter)
    cloneFilter = data
    setFilter(cloneFilter)
  }

  const handleResetFilter = () => {
    setFilter({
      company_id: "",
      year: "",
      plant_id: "",
    })
    setOriginData([])
  }
  

  const defaultColumns = [
    { 
      title: (
        <button className="flex flex-row w-[400px] items-center justify-between p-3" onClick={() => handleExpand()}>
          <span>Description</span>
          <img src={IconList.CheveronBottomGray} alt="" className={`${isExpand ? "rotate-180" : ""}`} />
        </button>
      ), 
      dataIndex: 'description', 
      key: 'description', 
      width: 400, 
      fixed: 'left',
      className: 'th-p-0 bordered-th font-medium z-[1] sticky left-0',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Jan
        </div>
      ),
      dataIndex: 'january', 
      key: 'january', 
      width: 145,
      className: 'th-p-0 bordered-top-left font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Feb
        </div>
      ),
      dataIndex: 'february', 
      key: 'february', 
      width: 145,
      className: 'th-p-0 bordered-top font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Mar
        </div>
      ),
      dataIndex: 'march', 
      key: 'march', 
      width: 145,
      className: 'th-p-0 bordered-top font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Apr
        </div>
      ),
      dataIndex: 'april', 
      key: 'april', 
      width: 145,
      className: 'th-p-0 bordered-top font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          May
        </div>
      ),
      dataIndex: 'may', 
      key: 'may', 
      width: 145,
      className: 'th-p-0 bordered-top font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Jun
        </div>
      ),
      dataIndex: 'june', 
      key: 'june', 
      width: 145,
      className: 'th-p-0 bordered-top font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Jul
        </div>
      ),
      dataIndex: 'july', 
      key: 'july', 
      width: 145,
      className: 'th-p-0 bordered-top font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Aug
        </div>
      ),
      dataIndex: 'august', 
      key: 'august', 
      width: 145,
      className: 'th-p-0 bordered-top font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Sep
        </div>
      ),
      dataIndex: 'september', 
      key: 'september', 
      width: 145,
      className: 'th-p-0 bordered-top font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Oct
        </div>
      ),
      dataIndex: 'october', 
      key: 'october', 
      width: 145,
      className: 'th-p-0 bordered-top font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Nov
        </div>
      ),
      dataIndex: 'november', 
      key: 'november', 
      width: 145,
      className: 'th-p-0 bordered-top font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
    { 
      title: (
        <div className="w-full h-full p-3">
          Dec
        </div>
      ),
      dataIndex: 'december', 
      key: 'december', 
      width: 145,
      className: 'th-p-0 bordered-top-right font-medium text-right-center relative',
      render: (row, record) => renderTableValue(row, record)
    },
  ]

  const handleExpand = () => {
    let cloneListData = _.cloneDeep(originData) 
    const insertAt = (arr, index, newItem) => [
      ...arr.slice(0, index),
      ...newItem, ...arr.slice(index)
    ];

    Object.keys(childData).forEach(function(key, parentIndex) {
      for (let i = 0; i < cloneListData.length; i++) {
        const element = cloneListData[i];
        const parentType = element.type.split("-")[0]
        console.log(parentType, key)

        if(parentType === key) {
          if(!isExpand) {
            cloneListData = insertAt(cloneListData, i+1, childData[key])              
          } else {
            cloneListData = _.pullAllBy(cloneListData, childData[key], "key")
          }
          break
        }
      }
    });

    setIsExpand(!isExpand)
    setOriginData(cloneListData)  
  }

  return (
    <div className="h-full w-full flex flex-col">
      <h1 className="text-4xl mb-5 capitalize">Vehicle Calculation</h1>
      <ContentHeader
        title="Employee Expense"
        onRefresh={() => getWarehouseCalculation("sync")}
        onClickFilter={() => setModalFilter(true)}
        onDownloadData={() => filter.plant_id === "" 
          ? toastHook.error("Table Cannot be Empty!") 
          : downloadVehicleCalculation(filter)
        }
      />
      <div className="w-full h-full flex-1 bg-white rounded-[16px] mt-4">
        <VehicleCalculationTable 
          tableColumns={defaultColumns}
          tableData={originData}
          isLoading={loadingTable}
        />
        {/* <VehicleCalculationTable 
          tableColumns={columns}
          tableData={listEmployeeExpense}
          colsFilter={colsFilter}
          isSelectAll={selectAll}
          paggingData={pagging}
          onPagingChange={handlePagging}
        /> */}
      </div>
      {/* Filter Modal */}
      <BaseModal 
        isOpen={isOpenModalFilter} 
        modalSize="max-w-[560px]"
        setIsOpen={() => setModalFilter(!isOpenModalFilter)}
        content={
          <FilterMasterDataExpense 
            detailData={filter}
            onClose={() => setModalFilter(!isOpenModalFilter)}
            onCancel={() => setCancelModal(!isOpenModalCancel)}
            onSave={handleFilterData}
            onReset={handleResetFilter}
          />
        }
      />
    </div>
  )
}

export default WarehouseCalculation;