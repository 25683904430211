import BaseButton from "components/Atoms/Button/basicButton";
import React from "react";

const NegativeCaseContent = ({title, description, onCancel, onAgree}) => {
  return (
    <div>
      <div className="flex flex-col pb-4 text-black gap-y-2">
        <span className="text-xl font-[600]">{title}</span>
        <span className="text-sm">{description}</span>
      </div>
      <div className="flex flex-row-reverse items-center gap-4 wrapper-save-cancel">
        <BaseButton title="Yes" type="filled" addClasses="w-full" onClick={onAgree} />
        <BaseButton title="No" type="outlined" addClasses="w-full" onClick={onCancel} />
      </div>
    </div>
  )
}

export default NegativeCaseContent;