import React from "react";
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash';
import "./custom.scss"
import BaseCheckbox from '../Checkbox/baseCheckbox';

const MenuButton = ({data}) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={data.onClick}
          className={`${
            active ? 'bg-[#EB008B] text-white' : 'text-gray-900'
          } group flex w-full items-center whitespace-nowrap rounded-md px-2 py-2 text-sm`}
        >
          {active ? (
            <img src={data.icon} alt=""/>
          ) : (
            <img src={data.icon} alt=""/>
          )}
          <span className="ml-2">{data.label}</span>
        </button>
      )}
    </Menu.Item>
  )
}

const MenuCheckbox = ({data, onChange}) => {
  return (
    <div className="text-gray-900 group flex w-full items-center rounded-md pr-2 pl-1 py-2 text-sm font-normal">
      <BaseCheckbox 
        onChange={onChange} 
        isChecked={data.isChecked}  
        label={data.label} 
        id={data.id}
        checkmarkWidth={.9}
        checkboxSize="w-[16px]"
      />
    </div>
  )
}

const MenuSingle = ({data, onClick}) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`
            ${active ? 'bg-[#EB008B] text-white-important' : 'text-gray-900'} 
            group flex w-full justify-center items-center rounded-md 
            button-dropdown-single text-sm`
          }
          onClick={() => onClick(data)}
        >
          {data}
        </button>
      )}
    </Menu.Item>
  )
}

export default function BaseDropdown({
  hasReset, 
  customHeader, 
  contentLabel,  
  icon, 
  title, 
  addClassName, 
  dropdownContent, 
  menuMaxHeight,
  menuPossition,
  menuWidth,
  callbackOnChange
}) {
  // eslint-disable-next-line no-unused-vars

  const handleReset = () => {
    callbackOnChange("reset")
  }

  return (
    <div className={`bg-[#FDE6F3] rounded-full ${addClassName}`}>
      <Menu as="div" className="relative inline-block text-left h-full">
        <Menu.Button className="button-menu px-10 py-3 flex items-center w-full h-full justify-center text-sm font-bold text-[#EB008B] hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          {title}
          {icon && (
            <img src={icon} alt="" className="ml-2"/>
          )}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={`absolute z-[3] ${menuWidth} ${menuPossition} ${menuMaxHeight} overflow-y-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
            <div className="px-1 py-1">
              {customHeader}
              {contentLabel && <span className="text-sm pl-2">{contentLabel}</span>}
              {dropdownContent.map((item, index) => {
                return _.every(dropdownContent, _.isObject) ? (
                  <Fragment key={`dropdown-menu-${index}`}>
                    {item.type === "checkbox" && <MenuCheckbox data={item} onChange={callbackOnChange} />}
                    {item.type !== "checkbox" && <MenuButton data={item} />}
                  </Fragment>
                ) : (
                  <Fragment key={`dropdown-menu-${index}`}>
                    <MenuSingle data={item} onClick={callbackOnChange}/>
                  </Fragment>
                )
              })}

              {hasReset && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => handleReset()}
                      className={`${
                        active ? 'bg-[#EB008B] text-white' : 'text-[#EB008B]'
                      } button-reset group flex w-full items-center justify-center rounded-md px-2 mt-2 text-sm font-normal`}
                    >
                      Reset
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

BaseDropdown.propTypes = {
  customHeader: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.element
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.element,
    PropTypes.number
  ]),
  icon: PropTypes.string,
  addClassName: PropTypes.string,
  dropdownContent: PropTypes.array,
  contentLabel: PropTypes.string,
  hasReset: PropTypes.bool,
  menuPossition: PropTypes.string,
  menuMaxHeight: PropTypes.string,
  menuWidth: PropTypes.string,
  callbackOnChange: PropTypes.func
};

BaseDropdown.defaultProps = {
  title: "",
  icon: "",
  addClassName: "",
  dropdownContent: [],
  contentLabel: "",
  hasReset: false,
  customHeader: "",
  menuPossition: "left-0",
  menuMaxHeight: "max-h-[200px]",
  menuWidth: "w-max",
  callbackOnChange: () => {}
};