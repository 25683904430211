import React from "react"
import PropTypes from 'prop-types';
import "./custom.scss"
import { IconList } from "assets/image";

const BaseCheckbox = ({label, checkmarkWidth, addClassName, isChecked, id, onChange, checkIcon, checkboxSize}) => {
  return (
    <button 
      onClick={() => onChange(id)}
      className={`checkbox-button-style bg-transparent flex flex-row items-center p-0 ${addClassName}`}>
      <span className={`${checkboxSize} h-auto`}>
        <img 
          className="w-full h-full" 
          src={isChecked ? checkIcon : IconList.CheckboxUnCheck} alt="" />
      </span>
      <span className="ml-3 pt-[2px]">{label}</span>
    </button>
  )
}

BaseCheckbox.propTypes = {
  label: PropTypes.string,
  addClassName: PropTypes.string,
  checkmarkWidth: PropTypes.number,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  checkboxSize: PropTypes.string,
  checkIcon: PropTypes.string,
};

BaseCheckbox.defaultProps = {
  label: "",
  addClassName: "",
  checkmarkWidth: 1,
  isChecked: false,
  onChange: () => {},
  id: 0,
  checkboxSize: "w-[24px]",
  checkIcon: IconList.CheckboxCheckAqua,
};

export default BaseCheckbox;