/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import { IconList } from "assets/image";
import { BaseButton, InputForm } from "components";
import _ from "lodash";
import * as yup from "yup";
import handleValidate from "helper/Hook/validation";
import { getBranchList, getCountryList } from "module/general/action";
import dayjs from "dayjs";

const FilterMasterDataExpense = ({detailData, onClose, onReset, onSave}) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [formToState, setFormToState] = useState([
    {
      title: "Company ID",
      placeholder: "Select",
      type: "select",
      value: "",
      name: "company_id",
      options: [],
      rules: yup.object().required("Company ID is required!").nullable(),
      error: ""
    },
    {
      title: "Branch/Plant ID",
      placeholder: "Select",
      type: "select",
      value: "",
      name: "plant_id",
      options: [],
      rules: yup.object().required("Branch/Plant ID is required!").nullable(),
      error: ""
    },
    {
      title: "Year",
      placeholder: "Select",
      type: "date-year",
      value: new Date(),
      name: "year",
      options: [],
      rules: yup.string().required("Year is required!").nullable(),
      error: ""
    },
  ])

  const handleChangeField = (event) => {
    const cloneForms = _.cloneDeep(formToState)
    const eventName = event.target.name
    const eventValue = event.target.value
    let filteredForm = _.filter(cloneForms, (data) => data.name === eventName)[0]
    filteredForm.value = eventValue
      
    setFormToState(cloneForms)
  }

  const handleChangeSelect = (event) => {
    const cloneForms = _.cloneDeep(formToState)
    const eventName = event.target.name
    let filteredForm = _.filter(cloneForms, (data) => data.name === eventName)[0]
    filteredForm.value = event
    
    setFormToState(cloneForms)
  }

  const handleOnChange = (event) => {
    const targetType = event.target.type
    switch (targetType) {
      case "text":
      case "date-year":
        handleChangeField(event)
        break;
      case "select":
        handleChangeSelect(event)
        break;    
      default:
        break;
    }
  }

  const handleSaveData = () => {
    let cloneForms = _.cloneDeep(formToState)
    const postDataObj = {}

    for (let i = 0; i < cloneForms.length; i++) {
      const element = cloneForms[i];
      if(element.type === "select") {
        postDataObj[element.name] = element.value.value
      } else if(element.type === "date-year") {
        postDataObj[element.name] = dayjs(element.value).format("YYYY")
      } else {
        postDataObj[element.name] = element.value
      }
    }

    const handleResetError = () => {
      cloneForms = cloneForms.map(result => ({...result, error: ""}))
    }

    handleValidate(formToState)
    .then((result) => {
      if(result.status === 200) {
        handleResetError()
        setFormToState(cloneForms)
        
        setButtonDisabled(true)
        onSave(postDataObj)
      } else {
        const errorsObj = result.data
        handleResetError()
        Object.keys(errorsObj).forEach(error => {
          const filteredForm = _.filter(cloneForms, (data) => data.name === error)[0]
          filteredForm.error = errorsObj[error]

          setFormToState(cloneForms)
        })
      }
    })
  }

  const resetFilter = () => {
    onReset()
    let cloneForms = _.cloneDeep(formToState)
    cloneForms = cloneForms.map(result => ({...result, value: ""}))
    setFormToState(cloneForms)
    onClose()
  }
  
  useEffect(() => {
    let cloneForm = _.cloneDeep(formToState)
    cloneForm[0].onSelectSearch = (inputValue, callback) => {
      const filter = { search: inputValue, currentPerPage: 999 }
      getCountryList(filter)
      .then(result => {
        const data = []
        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i];
          data.push({
            value: element.company_id,
            label: `${element.company_id} - ${element.description}`
          })
        }
        callback(data)
      })
    }

    cloneForm[1].onSelectSearch = (inputValue, callback) => {
      const filter = { search: inputValue, currentPerPage: 999 }
      getBranchList(filter)
      .then(result => {
        const data = []
        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i];
          data.push({
            value: element.plant_id,
            label: `${element.plant_id} - ${element.description}`
          })
        }
        callback(data)
      })
    }

    if(detailData.company_id !== "") {
      const renderValue = (data, index) => {
        if(data.type === "select") {
          return {label: detailData[data.name], value: detailData[data.name]}
        } else if(data.type === "date-year") {
          return new Date(`${detailData[data.name]}`)
        } else {
          return detailData[data.name]
        }
      }
  
      cloneForm = cloneForm.map((data, index) => {
        return {
          ...data,
          value: renderValue(data, index)
        }
      })
    }

    setFormToState(cloneForm)
  }, [])

  return (
    <div>
      <div className="flex flex-row items-center border-b-2 pb-3 text-black">
        <span className="flex flex-1 text-[20px]">Filter</span>
        <button onClick={onClose}>
          <img src={IconList.CloseIcon} alt=""/>
        </button>
      </div>
      <div className="flex flex-col mt-6 mb-4">
          <InputForm 
            formData={formToState.slice(0,3)}
            onChange={handleOnChange} 
          />
          <InputForm 
            formData={formToState.slice(3,5)}
            onChange={handleOnChange} 
            containerStyle="grid grid-cols-2 gap-4 mt-4"
          />
      </div>
      <div className="flex justify-center items-center gap-4 wrapper-save-cancel">
        <BaseButton title="Reset" type="outlined" onClick={resetFilter} isDisabled={isButtonDisabled} addClasses="w-[200px]"/>
        <BaseButton title="Apply" type="filled" onClick={handleSaveData} isDisabled={isButtonDisabled} addClasses="w-[200px]"/>
      </div>
    </div>
  )
}

export default FilterMasterDataExpense;