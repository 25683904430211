import apiList from 'constant/apiUrl';
import Api from 'helper/Api';
import toastHook from 'helper/Hook/toastr';
import _ from 'lodash';

// Get list
export const getListSalesmanCalculation = (filter, type) => {	 
	return Api().get(apiList.report.salesmanCalculation, {
		params: filter
		// {
		// 	// order: "updatedAt:desc",
		// 	company_id: "PP00",
		// 	plant_id: "P116",
		// 	year: "2021",
		// }
	})
	.then(result => {
		const returnData = result.data.data
		const {data, month, meta} = returnData
		const descriptionData = {
			avg: meta.price_ctn,
			edd: meta.plant_id,
			exchange: meta.rate_price
		}
		let mainData = []
		let childData = {}
		console.log(returnData.meta)

		const getBgColor = (item, itemName) => {
			if(item.background) {
				return item.background
			} else {
				if(item.calculation[itemName].background) {
					return `${item.calculation[itemName].background}`
				} else {
					return "#FFFFFF"
				}
			}
		}

		const renderValue = (value) => {
			if(_.isNumber(value)) {
				return value.toFixed(2)
			} else {
				return value ? value : "-"
			}
		}

		const renderClassName = (item, field) => {
			let addedClassname = ""
			if(item.background) {
				if(field === "january") {
					addedClassname = "border-l-2 rounded-l-md rounded-r-none"
				} else if (field === "description" || field === "std") {
					addedClassname = "rounded-md"
				} else if(field === "std2") {
					addedClassname = "border-r-2 rounded-r-md rounded-l-none"
				} else {
					addedClassname = "rounded-none"
				}
			}
			
			return `text-sm word-break-word ${item.bold ? "font-bold" : ""} ${addedClassname}`
		}

		if(!_.isEmpty(data)) {
			Object.keys(data).forEach(function(key, parentIndex) {
				if(data.hasOwnProperty(key)) {
					data[key].forEach((item, childIndex) => {
						if(childIndex === 0) {
							childData[key] = []
							mainData.push({ 
								description: {
									value: item.description.value,
									className: `text-sm ${item.bold ? "font-bold" : ""}`,
									bgColor: item.background ? item.background : "#FFFFFF"
								}, 
								january: {
									value: renderValue(item.calculation.january.formatted),
									className: renderClassName(item, "january"),
									bgColor: getBgColor(item, 'january')
								}, 
								february: {
									value: renderValue(item.calculation.february.formatted),
									className: renderClassName(item, "february"),
									bgColor: getBgColor(item, 'february')
								}, 
								march: {
									value: renderValue(item.calculation.march.formatted),
									className: renderClassName(item, "march"),
									bgColor: getBgColor(item, 'march')
								}, 
								april: {
									value: renderValue(item.calculation.april.formatted),
									className: renderClassName(item, "april"),
									bgColor: getBgColor(item, 'april')
								}, 
								may: {
									value: renderValue(item.calculation.may.formatted),
									className: renderClassName(item, "may"),
									bgColor: getBgColor(item, 'may')
								}, 
								june: {
									value: renderValue(item.calculation.june.formatted),
									className: renderClassName(item, "june"),
									bgColor: getBgColor(item, 'june')
								}, 
								july: {
									value: renderValue(item.calculation.july.formatted),
									className: renderClassName(item, "july"),
									bgColor: getBgColor(item, 'july')
								}, 
								august: {
									value: renderValue(item.calculation.august.formatted),
									className: renderClassName(item, "august"),
									bgColor: getBgColor(item, 'august')
								}, 
								september: {
									value: renderValue(item.calculation.september.formatted),
									className: renderClassName(item, "september"),
									bgColor: getBgColor(item, 'september')
								}, 
								october: {
									value: renderValue(item.calculation.october.formatted),
									className: renderClassName(item, "october"),
									bgColor: getBgColor(item, 'october')
								}, 
								november: {
									value: renderValue(item.calculation.november.formatted),
									className: renderClassName(item, "november"),
									bgColor: getBgColor(item, 'november')
								}, 
								december: {
									value: renderValue(item.calculation.december.formatted),
									className: renderClassName(item, "december"),
									bgColor: getBgColor(item, 'december')
								},
								key: parentIndex,
								type: `${key}-${parentIndex}`,
								className: `w-full px-3 py-1 rounded-md ${item.background ? "" : "border-x-2"} border-white`
							})
						} else {
							console.log(item.calculation.january.formatted)
							childData[key].push({
								description: {
									value: item.description.value,
									className: `text-sm ${item.bold ? "font-bold" : ""}`,
									bgColor: item.background ? item.background : "#FFFFFF"
								}, 
								january: {
									value: renderValue(item.calculation.january.formatted),
									className: renderClassName(item, "january"),
									bgColor: getBgColor(item, 'january')
								}, 
								february: {
									value: renderValue(item.calculation.february.formatted),
									className: renderClassName(item, "february"),
									bgColor: getBgColor(item, 'february')
								}, 
								march: {
									value: renderValue(item.calculation.march.formatted),
									className: renderClassName(item, "march"),
									bgColor: getBgColor(item, 'march')
								}, 
								april: {
									value: renderValue(item.calculation.april.formatted),
									className: renderClassName(item, "april"),
									bgColor: getBgColor(item, 'april')
								}, 
								may: {
									value: renderValue(item.calculation.may.formatted),
									className: renderClassName(item, "may"),
									bgColor: getBgColor(item, 'may')
								}, 
								june: {
									value: renderValue(item.calculation.june.formatted),
									className: renderClassName(item, "june"),
									bgColor: getBgColor(item, 'june')
								}, 
								july: {
									value: renderValue(item.calculation.july.formatted),
									className: renderClassName(item, "july"),
									bgColor: getBgColor(item, 'july')
								}, 
								august: {
									value: renderValue(item.calculation.august.formatted),
									className: renderClassName(item, "august"),
									bgColor: getBgColor(item, 'august')
								}, 
								september: {
									value: renderValue(item.calculation.september.formatted),
									className: renderClassName(item, "september"),
									bgColor: getBgColor(item, 'september')
								}, 
								october: {
									value: renderValue(item.calculation.october.formatted),
									className: renderClassName(item, "october"),
									bgColor: getBgColor(item, 'october')
								}, 
								november: {
									value: renderValue(item.calculation.november.formatted),
									className: renderClassName(item, "november"),
									bgColor: getBgColor(item, 'november')
								}, 
								december: {
									value: renderValue(item.calculation.december.formatted),
									className: renderClassName(item, "december"),
									bgColor: getBgColor(item, 'december')
								},
								key: `${key}-child-${parentIndex}-${childIndex}`,
								type: `${key}-child-${parentIndex}-${childIndex}`,
								className: `w-full px-3 py-1 rounded-md ${item.background ? "" : "border-x-2"} border-white`
							})
						}
					})
				}
			});
		} else {
			toastHook.error("Oops! data is empty!")
		}
		
		// console.log(mainData)
		// console.log(childData)
		if(type === "sync") {
			toastHook.success("Sync data successfully!")
		}
		return {
			mainData, 
			childData, 
			month,
			descriptionData
		}

	})
	.catch(err => {
		if(type === "sync") {
			toastHook.error("Oops! unable to sync data. try again")
		} else {
			toastHook.error(err.message)
		}
		return err
	})
}

// Add or Edit
export const createMasterDataExpense = (postdata) => {
	const {formToState, detailData} = postdata
	const postDataObj = {}

	for (let i = 0; i < formToState.length; i++) {
		const element = formToState[i];
		postDataObj[element.name] = ~~element.value
	}
	postDataObj.company_id = detailData.company_id
	postDataObj.plant_id = detailData.plant_id
	postDataObj.year = detailData.year
	postDataObj.expense_type = detailData.expense_type_child
	postDataObj.expense_description = detailData.expense_type
	
	console.log(postDataObj)

	return Api()({
		url: apiList.expense.masterDataExpense,
		data: postDataObj,
		method: "POST",
	})
	.then(result => {
		return result
	})
	.catch(err => {
		console.log(err.response.data.message)
		toastHook.error(err.response.data.message)
		return {status: err.response.status}
	})
}

// Delete
export const deleteListWorkingDays = (ids) => {
	return Api().patch(apiList.warehouse.workingDays+`/delete`,{
			permanent: false,
			id: ids
		})
	.then(result => {
		const status = result.status
		
		return status
	})
	.catch(err => toastHook.error(err.message))
}

// Export
export const downloadListWorkingDays = () => {
	return Api().get(apiList.warehouse.workingDays+"/export")
	.then(result => {
		console.log(result)
	})
	.catch(err => console.log(err))
}