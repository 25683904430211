/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import Table from "rc-table";
import PropTypes from 'prop-types';
import PaginationTable from "./paginationTable";
import "./custom.scss";
import _ from "lodash";
import { BgList } from "assets/image";
import { TableLoading } from "components";

const BaseTable = ({
  paggingData, 
  tableColumns, 
  tableData, 
  colsFilter, 
  onPagingChange,
  isLoading
}) => {
  console.log(tableData)
  return (
    <TableLoading isLoading={isLoading}>
      {
        tableData.length !== 0 ? (
          <div className="p-4 h-full flex flex-col relative">
            <Table 
              className="custom-table" 
              columns={
                tableColumns.map(result => {
                  const columnClassName = result.className ? result.className : ""
                  const colsData = _.filter(colsFilter, cols => cols.key === result.key)[0]
                  const generateClassName = () => {
                    if(!_.isUndefined(colsData)) {
                      return colsData.isChecked ? "" : "hidden"
                    }
                  }
                  
                  return {
                    ...result,
                    className: `${generateClassName()} ${columnClassName}`
                  }
                })
              }
              data={tableData}
              footer={currentData => 
                <PaginationTable 
                  totalPage={paggingData.pagging_data_detail.total_page_filtered}
                  totalRecord={paggingData.total_record}
                  currentPage={paggingData.pagging_data_detail.current_page}
                  itemMin={paggingData.pagging_data_detail.record_min}
                  itemMax={paggingData.pagging_data_detail.record_max}
                  paginationCallback={onPagingChange}
                />
              }
            />
          </div>
        ) : (
          <div className="w-full h-full bg-white relative rounded-[16px] top-0 left-0  flex items-center justify-center flex-col">
            <span className="absolute top-5 left-5 text-[#1A727A] text-md">Search Result : 0</span> 
            <img src={BgList.EmptyData} alt="" />
            <h1 className="text-2xl mt-5">The Data You Are Looking for Cannot be Found</h1>
            <span className="text-md mt-3 text-[#444444]">Don't worry you can Create a new data</span>
            <div className="absolute bottom-0 p-3 w-full">
              {!_.isEmpty(paggingData) && (
                <PaginationTable 
                  totalPage={paggingData.pagging_data_detail.total_page_filtered}
                  totalRecord={paggingData.total_record}
                  currentPage={paggingData.pagging_data_detail.current_page}
                  itemMin={paggingData.pagging_data_detail.record_min}
                  itemMax={paggingData.pagging_data_detail.record_max}
                  paginationCallback={onPagingChange}
                />
              )}
            </div>
          </div>
        )
      }
    </TableLoading>
  )
}

BaseTable.propTypes = {
  tableColumns: PropTypes.array,
  tableData: PropTypes.array,
  onActionClick: PropTypes.func,
  colsFilter: PropTypes.array,
  paggingData: PropTypes.object,
  onPagingChange: PropTypes.func,
  isLoading: PropTypes.bool
};

BaseTable.defaultProps = {
  tableColumns: [],
  tableData: [],
  onActionClick: () => {},
  colsFilter: [],
  isLoading: false,
  paggingData: {
    total_record: 0,
    pagging_data_detail: {
      total_page_filtered: 0,
      current_page: 0,
      record_min: 0,
      record_max: 0
    }
  },
  onPagingChange: () => {},
};

export default BaseTable;