import React, { useState } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { IconList } from "assets/image";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.scss";

const Menu = props => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 12,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 4,
        width: '365px',
        overflow: 'hidden',
      }}
      {...props}
    />
  );
};

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div className="relative border-[1px] border-[#BBBBBB] rounded-md">
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {/* {isOpen ? <div className="top-0 left0 fixed z-[1]" onClick={onClose} /> : null} */}
  </div>
); 

const days = ["M", "S", "S", "R", "K", "J", "S"];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const locale = {
  localize: {
    day: (n) => days[n],
    month: (n) => months[n],
  },
  formatLong: {
    date: () => "mm/dd/yyyy",
  },
};

const InputMonth = ({ data, onChange, endDate, maxDate }) => {
  const [isOpen, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  const handleOnChange = (value) => {
    const dateData = {
      target: {
        type: data.type,
        name: data.name,
        value: value
      }
    }
    toggleOpen()
    onChange(dateData)
  }

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <button
          disabled={isOpen}
          onClick={toggleOpen}
          className="w-full text-left px-3 py-2 flex items-center justify-between"
        >

          <span className={data.value ? "text-black" : "text-[#AAAAAA]" }>{data.value ? dayjs(data.value).format("MMMM") : data.placeholder}</span>
          <img src={IconList.ChevronDownGray} alt="" />
        </button>
      }
    >
      <DatePicker
        className="shadow-higher"
        selected={data.value}
        onChange={handleOnChange}
        startDate={data.value}
        endDate={endDate}
        inline
        locale={locale}
        onClickOutside={toggleOpen}
        showMonthYearPicker
        yearItemNumber={16}
        format
        // minDate={data.minDate}
        renderCustomHeader={({
          date,
        }) => (
          <div
            className="flex w-full flex-row" 
            style={{
              margin: "0px 10px",
              justifyContent: "center",
            }}
          >
            <h3 className="font-roboto font-medium text-[24px] flex-1 uppercase">
              {dayjs(date).format("MMMM")}
            </h3>
          </div>
        )}
      />
    </Dropdown>
  );
}

InputMonth.propTypes = {
  data: PropTypes.object,
};

InputMonth.defaultProps = {
  data: {
    title: "",
    placeholder: "",
    type: "",
    onChange: () => {},
    value: null,
    options: [
      { value: "", label: "" }
    ],
    onSelectSearch: null // for async process
  }
};

export default InputMonth;
