/* eslint-disable react-hooks/exhaustive-deps */
import { IconList } from "assets/image";
import { BaseModal, ContentHeader } from "components";
import _ from "lodash";
import { downloadListEmployeeExpense } from "module/expense/employeeExpense/action";
import FilterMasterDataExpense from "./filter/filter";
import React, {useState} from "react";
import SalesmanCalculationTable from "components/Molecules/Table/salesmanCalculationTable";
import { getListSalesmanCalculation } from "module/reports/salesmanCalculation/action";

const SalesmanCalculation = () => {
  const [originData, setOriginData] = useState([])
  const [childData, setChildData] = useState([])
  const [descData, setDescData] = useState({
    avg: "-",
    edd: "-",
    exchange: {
      from: "-",
      to: "-",
      value: 0
    }
  })
  const [isExpand, setIsExpand] = useState(false);
  const [isOpenModalFilter, setModalFilter] = useState(false)
  const [isOpenModalCancel, setCancelModal] = useState(false) 
  const [loadingTable, setLoadingTable] = useState(false)
  const [filter, setFilter] = useState({
    company_id: "",
    year: new Date(),
    plant_id: "",
  })

  // get getSalesmanCalculation list
  React.useEffect(() => {
    // getSalesmanCalculation()
    if(filter.company_id !== "") {
      getSalesmanCalculation()
    }
  }, [filter])

  const getSalesmanCalculation = (type) => {
    setLoadingTable(true)
    getListSalesmanCalculation(filter, type)
    .then(result => {
      setLoadingTable(false)
      setOriginData(result.mainData)
      setChildData(result.childData)
      setDescData(result.descriptionData)
      setModalFilter(false)
    })
  }

  const handleFilterData = (data) => {
    let cloneFilter = _.cloneDeep(filter)
    cloneFilter = data
    setFilter(cloneFilter)
  }

  const handleResetFilter = () => {
    setFilter({
      company_id: "",
      year: new Date(),
      plant_id: "",
    })
    setOriginData([])
    setDescData({
      avg: "-",
      edd: "-",
      exchange: {
        from: "-",
        to: "-",
        value: 0
      }
    })
  }


  const defaultColumns = [
    { 
      title: (
        <button className="flex flex-row w-[330px] items-center justify-between p-3" onClick={() => handleExpand()}>
          <span>Description</span>
          <img src={IconList.CheveronBottomGray} alt="" className={`${isExpand ? "rotate-180" : ""}`} />
        </button>
      ), 
      dataIndex: 'description', 
      key: 'description', 
      // width: "20%", 
      // fixed: 'left',
      className: 'th-p-0 bordered-th font-medium z-[1] sticky left-0'
      // h-[50px]
      ,
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className}`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
  ]
  
  const addedColumns = [
    { 
      title: (
        <div className="h-full p-3 w-[130px]">
          Jan
        </div>
      ),
      dataIndex: 'january', 
      key: 'january', 
      className: 'th-p-0 bordered-top-left font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          Feb
        </div>
      ),
      dataIndex: 'february', 
      key: 'february', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          Mar
        </div>
      ),
      dataIndex: 'march', 
      key: 'march', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          Apr
        </div>
      ),
      dataIndex: 'april', 
      key: 'april', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          May
        </div>
      ),
      dataIndex: 'may', 
      key: 'may', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          Jun
        </div>
      ),
      dataIndex: 'june', 
      key: 'june', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          Jul
        </div>
      ),
      dataIndex: 'july', 
      key: 'july', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          Aug
        </div>
      ),
      dataIndex: 'august', 
      key: 'august', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          Sep
        </div>
      ),
      dataIndex: 'september', 
      key: 'september', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          Oct
        </div>
      ),
      dataIndex: 'october', 
      key: 'october', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          Nov
        </div>
      ),
      dataIndex: 'november', 
      key: 'november', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-[130px] h-full p-3">
          Dec
        </div>
      ),
      dataIndex: 'december', 
      key: 'december', 
      className: 'th-p-0 bordered-top-right font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
  ]

  const handleExpand = () => {
    let cloneListData = _.cloneDeep(originData) 
    const insertAt = (arr, index, newItem) => [
      ...arr.slice(0, index),
      ...newItem, ...arr.slice(index)
    ];

    Object.keys(childData).forEach(function(key, parentIndex) {
      for (let i = 0; i < cloneListData.length; i++) {
        const element = cloneListData[i];
        const parentType = element.type.split("-")[0]

        if(parentType === key) {
          if(!isExpand) {
            cloneListData = insertAt(cloneListData, i+1, childData[key])              
            console.log(cloneListData)
          } else {
            cloneListData = _.pullAllBy(cloneListData, childData[key], "key")
          }
          break
        }
      }
    });

    setIsExpand(!isExpand)
    setOriginData(cloneListData)  
  }

  const expect = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"
  ]

  const renderColumn = () => {
    var removeElements = _.remove(addedColumns, obj => expect.includes(obj.key));
    return [...defaultColumns, ...removeElements]
  }

  return (
    <div className="h-full w-full flex flex-col">
      <h1 className="text-4xl mb-5 capitalize">Salesman Calculation</h1>
      <ContentHeader
        title="Employee Expense"
        onRefresh={() => getSalesmanCalculation("sync")}
        onClickFilter={() => setModalFilter(true)}
        onDownloadData={() => downloadListEmployeeExpense()}
      />
      <div className="w-full h-full flex-1 bg-white rounded-[16px] mt-4">
        <SalesmanCalculationTable 
          tableColumns={renderColumn()}
          tableData={originData}
          descData={descData}
          isLoading={loadingTable}
        />
      </div>
      {/* Filter Modal */}
      <BaseModal 
        isOpen={isOpenModalFilter} 
        modalSize="max-w-[560px]"
        setIsOpen={() => setModalFilter(!isOpenModalFilter)}
        content={
          <FilterMasterDataExpense 
            detailData={filter}
            onClose={() => setModalFilter(!isOpenModalFilter)}
            onCancel={() => setCancelModal(!isOpenModalCancel)}
            onSave={handleFilterData}
            onReset={handleResetFilter}
          />
        }
      />
    </div>
  )
}

export default SalesmanCalculation;