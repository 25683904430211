import apiList from 'constant/apiUrl';
import dayjs from 'dayjs';
import Api from 'helper/Api';
import { downloadFile, objectToQueryString } from 'helper/functions';
import toastHook from 'helper/Hook/toastr';
import _ from 'lodash';

// Get list
export const getListWarehouseCalculation = (filter, type) => {
	// console.log(filter)
	let cloneFilter = _.cloneDeep(filter)
	cloneFilter.start_month = new Date(cloneFilter.start_month).getMonth() + 1;
	cloneFilter.end_month = new Date(cloneFilter.end_month).getMonth() + 1;
	 
	return Api().get(apiList.report.warehouseCalculation, {
		params: cloneFilter
		// {
		// 	// order: "updatedAt:desc",
		// 	company_id: "PP00", 
		// 	year: "2021",
		// 	plant_id: "P116",
		// 	start_month: "1",
		// 	end_month: "12",
		// }
	})
	.then(result => {
		const returnData = result.data.data
		const {data, month} = returnData
		let mainData = []
		let childData = {}

		const getBgColor = (item, itemName) => {
			if(item.background) {
				return item.background
			} else {
				if(item.calculation[itemName].background) {
					return `${item.calculation[itemName].background}`
				} else {
					return "#FFFFFF"
				}
			}
		}

		const renderValue = (value) => {
			if(_.isNumber(value)) {
				return value.toFixed(2)
			} else {
				return value ? value : "-"
			}
		}

		const renderClassName = (item, field) => {
			let addedClassname = ""
			if(item.background) {
				if(field === "january") {
					addedClassname = "border-l-2 rounded-l-md rounded-r-none"
				} else if (field === "description" || field === "std") {
					addedClassname = "rounded-md"
				} else if(field === "std2") {
					addedClassname = "border-r-2 rounded-r-md rounded-l-none"
				} else {
					addedClassname = "rounded-none"
				}
			}
			
			return `text-sm word-break-word ${item.bold ? "font-bold" : ""} ${addedClassname}`
		}

		if(!_.isEmpty(data)) {
			Object.keys(data).forEach(function(key, parentIndex) {
				if(data.hasOwnProperty(key)) {
					data[key].forEach((item, childIndex) => {
						if(childIndex === 0) {
							childData[key] = []
							mainData.push({ 
								description: {
									value: item.description.value,
									className: `text-sm ${item.bold ? "font-bold" : ""}`,
									bgColor: item.background ? item.background : "#FFFFFF"
								}, 
								std: {
									value: renderValue(item.description.standard),
									className: renderClassName(item, "std"),
									bgColor: getBgColor(item, 'std')
								},
								std2: {
									value: renderValue(item.calculation.std.formatted),
									className: renderClassName(item, "std2"),
									bgColor: getBgColor(item, 'std')
								},
								january: {
									value: renderValue(item.calculation.january.formatted),
									className: renderClassName(item, "january"),
									bgColor: getBgColor(item, 'january')
								}, 
								february: {
									value: renderValue(item.calculation.february.formatted),
									className: renderClassName(item, "february"),
									bgColor: getBgColor(item, 'february')
								}, 
								march: {
									value: renderValue(item.calculation.march.formatted),
									className: renderClassName(item, "march"),
									bgColor: getBgColor(item, 'march')
								}, 
								april: {
									value: renderValue(item.calculation.april.formatted),
									className: renderClassName(item, "april"),
									bgColor: getBgColor(item, 'april')
								}, 
								may: {
									value: renderValue(item.calculation.may.formatted),
									className: renderClassName(item, "may"),
									bgColor: getBgColor(item, 'may')
								}, 
								june: {
									value: renderValue(item.calculation.june.formatted),
									className: renderClassName(item, "june"),
									bgColor: getBgColor(item, 'june')
								}, 
								july: {
									value: renderValue(item.calculation.july.formatted),
									className: renderClassName(item, "july"),
									bgColor: getBgColor(item, 'july')
								}, 
								august: {
									value: renderValue(item.calculation.august.formatted),
									className: renderClassName(item, "august"),
									bgColor: getBgColor(item, 'august')
								}, 
								september: {
									value: renderValue(item.calculation.september.formatted),
									className: renderClassName(item, "september"),
									bgColor: getBgColor(item, 'september')
								}, 
								october: {
									value: renderValue(item.calculation.october.formatted),
									className: renderClassName(item, "october"),
									bgColor: getBgColor(item, 'october')
								}, 
								november: {
									value: renderValue(item.calculation.november.formatted),
									className: renderClassName(item, "november"),
									bgColor: getBgColor(item, 'november')
								}, 
								december: {
									value: renderValue(item.calculation.december.formatted),
									className: renderClassName(item, "december"),
									bgColor: getBgColor(item, 'december')
								},
								key: parentIndex,
								type: `${key}-${parentIndex}`,
								className: `w-full px-3 py-1 rounded-md ${item.background ? "" : "border-x-2"} border-white`
							})
						} else {
							// console.log(item.calculation.january.formatted)
							childData[key].push({
								description: {
									value: item.description.value,
									className: `text-sm ${item.bold ? "font-bold" : ""}`,
									bgColor: item.background ? item.background : "#FFFFFF"
								}, 
								std: {
									value: renderValue(item.description.standard),
									className: renderClassName(item, "std"),
									bgColor: getBgColor(item, 'std')
								},
								std2: {
									value: renderValue(item.calculation.std.formatted),
									className: renderClassName(item, "std2"),
									bgColor: getBgColor(item, 'std')
								},
								january: {
									value: renderValue(item.calculation.january.formatted),
									className: renderClassName(item, "january"),
									bgColor: getBgColor(item, 'january')
								}, 
								february: {
									value: renderValue(item.calculation.february.formatted),
									className: renderClassName(item, "february"),
									bgColor: getBgColor(item, 'february')
								}, 
								march: {
									value: renderValue(item.calculation.march.formatted),
									className: renderClassName(item, "march"),
									bgColor: getBgColor(item, 'march')
								}, 
								april: {
									value: renderValue(item.calculation.april.formatted),
									className: renderClassName(item, "april"),
									bgColor: getBgColor(item, 'april')
								}, 
								may: {
									value: renderValue(item.calculation.may.formatted),
									className: renderClassName(item, "may"),
									bgColor: getBgColor(item, 'may')
								}, 
								june: {
									value: renderValue(item.calculation.june.formatted),
									className: renderClassName(item, "june"),
									bgColor: getBgColor(item, 'june')
								}, 
								july: {
									value: renderValue(item.calculation.july.formatted),
									className: renderClassName(item, "july"),
									bgColor: getBgColor(item, 'july')
								}, 
								august: {
									value: renderValue(item.calculation.august.formatted),
									className: renderClassName(item, "august"),
									bgColor: getBgColor(item, 'august')
								}, 
								september: {
									value: renderValue(item.calculation.september.formatted),
									className: renderClassName(item, "september"),
									bgColor: getBgColor(item, 'september')
								}, 
								october: {
									value: renderValue(item.calculation.october.formatted),
									className: renderClassName(item, "october"),
									bgColor: getBgColor(item, 'october')
								}, 
								november: {
									value: renderValue(item.calculation.november.formatted),
									className: renderClassName(item, "november"),
									bgColor: getBgColor(item, 'november')
								}, 
								december: {
									value: renderValue(item.calculation.december.formatted),
									className: renderClassName(item, "december"),
									bgColor: getBgColor(item, 'december')
								},
								key: `${key}-child-${parentIndex}-${childIndex}`,
								type: `${key}-child-${parentIndex}-${childIndex}`,
								className: `w-full px-3 py-1 rounded-md ${item.background ? "" : "border-x-2"} border-white`
							})
						}
						
						// if(childIndex === 0) {
						// 	mainData.push({ 
						// 		uom: item.uom, 
						// 		expense_type: item.expense_type ? item.expense_type : "total", 
						// 		expense_sales_supervisor: item.expense_sales_supervisor, 
						// 		expense_sales_repentative: item.expense_sales_repentative, 
						// 		expense_account_officer: item.expense_account_officer, 
						// 		expense_finance_officer: item.expense_finance_officer, 
						// 		expense_sales_admin_officer: item.expense_sales_admin_officer, 
						// 		expense_logistic_officer: item.expense_logistic_officer, 
						// 		expense_driver: item.expense_driver, 
						// 		expense_delivery_assistant: item.expense_delivery_assistant, 
						// 		expense_warehouse_helper: item.expense_warehouse_helper, 
						// 		total_expense_desc: item.total_expense_desc, 
						// 		company_id: item.company_id, 
						// 		year: item.year,
						// 		plant_id: item.plant_id,
						// 		key: parentIndex,
						// 		type: `parent-${parentIndex}`,
						// 		className: item.expense_type ? "" : "", 
						// 		isExpand: false
						// 	})
						// } else {
						// 	childData[key].push({
						// 		uom: item.uom, 
						// 		expense_type: item.expense_description, 
						// 		expense_type_child: item.expense_type, 
						// 		expense_sales_supervisor: item.expense_sales_supervisor, 
						// 		expense_sales_repentative: item.expense_sales_repentative, 
						// 		expense_account_officer: item.expense_account_officer, 
						// 		expense_finance_officer: item.expense_finance_officer, 
						// 		expense_sales_admin_officer: item.expense_sales_admin_officer, 
						// 		expense_logistic_officer: item.expense_logistic_officer, 
						// 		expense_driver: item.expense_driver, 
						// 		expense_delivery_assistant: item.expense_delivery_assistant, 
						// 		expense_warehouse_helper: item.expense_warehouse_helper, 
						// 		total_expense_desc: item.total_expense_desc,
						// 		company_id: item.company_id, 
						// 		year: item.year,
						// 		plant_id: item.plant_id,
						// 		key: `child-${parentIndex}-${childIndex}`,
						// 		type: `child-${parentIndex}-${childIndex}`,
						// 	})
						// }
					})
				}
			});
		} else {
			toastHook.error("Oops! data is empty!")
		}
		
		// console.log(mainData)
		// console.log(childData)
		if(type === "sync") {
			toastHook.success("Sync data successfully!")
		}
		return {mainData, childData, month}

	})
	.catch(err => {
		if(type === "sync") {
			toastHook.error("Oops! unable to sync data. try again")
		} else {
			toastHook.error(err.message)
		}
		return err
	})
}

export const downloadWarehouseReport = (filter) => {
	let cloneFilter = _.cloneDeep(filter)	
	cloneFilter.start_month = dayjs(cloneFilter.start_month).month() + 1
	cloneFilter.end_month = dayjs(cloneFilter.end_month).month() + 1

	return downloadFile(apiList.report.warehouseCalculation+`/export${objectToQueryString(cloneFilter)}`, "warehouse_report")
}