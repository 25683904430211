import React, {useState} from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from 'react-pro-sidebar';
import "assets/css/sidebar.scss";
import { BgList, IconList } from 'assets/image';
import NormalSelect from "components/Atoms/Select/normalSelect";
import sidebarMenu from 'constant/sidebarMenu';
import { useNavigate, useLocation } from 'react-router-dom';

const setMenuHandler = (currentRoute, nextRoute, isOpen) => {
  if(currentRoute === nextRoute) {
    return isOpen
  } else if (currentRoute !== nextRoute) {
    return true
  }
}

const Sidebar = ({ collapsed, rtl, toggled, handleToggleSidebar, handleCollapsedChange }) => {
  const [itemRoute, setItemRoute] = useState("/");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const splitedLocation = `/${location.pathname.split('/')[1]}`; 
  
  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader className="px-6">
        <div className="pt-5 pb-3 flex content-center items-center relative flex-col">
          <img
            className="h-5 w-auto"
            src={IconList.PoweredEdot}
            alt=""
          />
          <img
            className="h-12 w-22 mt-6"
            src={BgList.LogoNabati}
            alt=""
          />
          <button className="absolute top-[45px] -right-3 rounded-md bg-[#AAE5EA] p-2" onClick={handleCollapsedChange}>
            <img
              className="h-3 w-3"
              src={IconList.ChevronLeftWhite}
              alt=""
            />
          </button>
        </div>
        <NormalSelect />
      </SidebarHeader>

      <SidebarContent>
        {sidebarMenu.map((result, index) => {
          return (
            <div key={`sidebar-parent-${index}`}>
              <div className="px-6 mt-4">
                <span className="font-[600] text-xs uppercase">{result.label}</span>
                {result.menu.map((item, index) => {
                  return !item.child ? (
                    <Menu key={`menu-lv1-${index}`} className="bordered-menu" iconShape="circle">
                      <MenuItem 
                        onClick={() => navigate(item.route)} 
                        icon={<img src={item.icon} alt="" />} 
                        className={`standalone-menu ${location.pathname === item.route && "active"}`}>
                        {item.title}
                      </MenuItem>
                    </Menu>
                  ) : (
                    <Menu key={`menu-lv1-${index}`} 
                      iconShape="circle" 
                      className="parent-standalone-menu">
                      <SubMenu
                        title={item.title}
                        icon={<img src={item.icon} alt="" />}
                        className={`standalone-menu ${splitedLocation === item.route && "active"}`}
                        // defaultOpen={splitedLocation === item.route}
                        open={(itemRoute === item.route) && isMenuOpen}
                        onOpenChange={e => {
                          setMenuOpen(setMenuHandler(itemRoute, item.route, !isMenuOpen))
                          setItemRoute(item.route)
                        }}
                      >
                        {item.child.map((child, index) => {
                          return <MenuItem 
                            onClick={() => {
                              setItemRoute(item.route) 
                              navigate(child.route) 
                            }} 
                            key={`menu-lv2-${index}`}
                            className={`standalone-dropdown ${location.pathname === child.route && "active"}`}
                            >
                              {child.title}
                            </MenuItem>
                        })}
                      </SubMenu>
                    </Menu>
                  )
                })}
              </div>
            </div>
          )
        })}
        
      </SidebarContent>
    </ProSidebar>
  );
};

export default Sidebar;