import axios from "axios";
import { getCookies, removeCookies, setCookies } from "./functions";
import _ from "lodash"
import config from "constant/config";

const success = (res) => {
  return {
    status: true,
    statusCode: res.status,
    statusText: res.statusText,
    headers: res.headers,
    data: res.data,
  };
};

const error = (res) => {
  let data = {};

  if (res.response) {
    data = {
      status: false,
      statusCode: res.response.status,
      statusText: res.response.status.statusText,
      headers: res.response.headers,
      data: res.response.data,
    };
  } else if (res.request) {
    data = {
      status: false,
      statusCode: 0,
      statusText: res.request,
      headers: res.request.headers,
      data: { message: "Koneksi bermasalah" },
    };
  } else {
    data = {
      status: false,
      statusCode: 0,
      statusText: res.message,
      headers: res.headers,
      data: { message: res.message },
    };
  }

  return data;
};

const headerData = ({ token, ...data } = {}) => {
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...data,
  };
  if (token) {
    header["Authorization"] = `Bearer ${token}`;
  }

  return header;
};

let cancelRequest;

const Api = ({ headers, withAuth = false, ...axiosConfig } = {}) => {
  const CancelToken = axios.CancelToken;

  const instance = axios.create({
    baseURL: config.ApiUrl,
    headers: headerData(headers),
    cancelToken: new CancelToken(function executor(c) {
      cancelRequest = c;
    }),
    ...axiosConfig,
  });

  instance.interceptors.request.use(
    async function (response) {
      const userProps = JSON.parse(getCookies('nabatiAuth'))
      if (withAuth) {
        if (userProps === null) {
          console.log("Session Expired remove session")
          return false;
        } else {
          const auth = JSON.parse(getCookies('nabatiAuth') || "{}");
          if (!Object.keys(auth).length) {
            return response;
          }
          response.headers = headerData({ token: auth.accessToken });
          // handleRefresh()
        }
      }
      
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function(error) {
      if(error.response.status === 401) {
        debouncedSomeFunction()
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

const handleRefresh = async => {
  const userProps = JSON.parse(getCookies('nabatiAuth'))
  const axiosServiceRefresh = axios.create({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  return new Promise((resolve, reject) => {
    axiosServiceRefresh
      .post(process.env.REACT_APP_API_URL+"/auth/refresh-token", {
        refreshToken: userProps.refreshToken
      })
      .then(response => {
        const {accessToken, refreshToken} = response.data.data
        const userProps = _.cloneDeep(JSON.parse(getCookies('nabatiAuth')))
        userProps.accessToken = accessToken 
        userProps.refreshToken = refreshToken
        setCookies("nabatiAuth", JSON.stringify(userProps), 120)
        window.location.reload()
        resolve(true);
      })
      .catch(error => {
        // if(error.response.status === 401) {
          removeCookies("nabatiAuth","/")
          window.location.href = "/login"
        // }
        reject(false);
      });
  });
};

const debouncedSomeFunction = debounce(handleRefresh, 1000);

function debounce(fn, bufferInterval) {
  var timeout;

  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(fn.apply.bind(fn, this, arguments), bufferInterval);
  };

}

export default Api;
export { cancelRequest as CancelRequest };
export { success as ResponseSuccess };
export { error as ResponseError };

