import React from 'react';
import { FaBars } from 'react-icons/fa';
import Navbar from './navbar';
import Router from 'Routing';

const ContentContainer = ({
  collapsed,
  handleToggleSidebar,
  handleCollapsedChange,
}) => {
  return (
    <main>
      <Navbar handleCollapsedChange={handleCollapsedChange}/>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <section className="w-full h-full p-6" style={{backgroundColor: "#E5E5E5"}}>
        <Router />
      </section>
    </main>
  );
};

export default ContentContainer;