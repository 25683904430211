import React from "react"
import PropTypes from 'prop-types';
import "./inputRadio.scss"
import { IconList } from "assets/image";

const InputCheckbox = ({options, addClassName, onChange, checkIcon, radioSize, name}) => {
  return (
    <div className="flex flex-col gap-y-5">
      {options.map((option, index) => {
        return (
          <button
            key={`radio-${index}`}
            onClick={() => onChange({target: {type: "radio", value: option.id, name: name}})}
            className={`radio-button-style ${option.isChecked ? "checked-radio" : "unchecked-radio"} rounded-md bg-transparent px-3 py-2 w-full flex flex-row items-center ${addClassName}`}>
            <span className={`h-auto absolute ${radioSize}`}>
              <img 
                className="w-full h-full" 
                src={option.isChecked ? checkIcon : IconList.RadioUncheck} alt="" />
            </span>
            <span className="ml-3 pt-[2px] w-full">{option.label}</span>
          </button>
        )
      })}
    </div>
  )
}

InputCheckbox.propTypes = {
  label: PropTypes.string,
  addClassName: PropTypes.string,
  checkmarkWidth: PropTypes.number,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.number,
  radioSize: PropTypes.string,
  checkIcon: PropTypes.string,
  options: PropTypes.array
};

InputCheckbox.defaultProps = {
  title: "",
  addClassName: "",
  checkmarkWidth: 1,
  isChecked: false,
  onChange: () => {},
  id: 0,
  radioSize: "w-[24px]",
  checkIcon: IconList.RadioCheck,
  options: []
};

export default InputCheckbox;