const renderClassName = (item, field, separate) => {
  let addedClassname = ""
  if(item.background) {
    if(field === "january") {
      addedClassname = separate ? "border-r-2 rounded-md" : "rounded-l-md rounded-r-none"
    } else if (field === "december") {
      addedClassname = separate ? "border-l-2 rounded-md" : "rounded-r-md rounded-l-none"
    }else {
      addedClassname = separate ? "border-x-2 rounded-md" : "rounded-none"
    }
  }
  
  return `text-sm word-break-word ${item.bold ? "font-bold" : ""} ${addedClassname} absolute top-0 left-0 h-full flex items-center justify-end`
}

const getBgColor = (item, itemName) => {
  if(item.background) {
    return item.background
  } else {
    if(item.calculation[itemName].background) {
      return `${item.calculation[itemName].background}`
    } else {
      return "#FFFFFF"
    }
  }
}

// ------------ end of local helper ----------

export const assignTkoDataMidData = (tkoData, key, parentIndex) => {
  return { 
    description: {
      value: tkoData.description.value,
      className: 'w-full flex flex-row justify-center gap-1 text-sm',
      bgColor: tkoData.background ? tkoData.background : "#FFFFFF",
      children: [
        {
          label: tkoData.description.value,
          className: 'w-[200px] py-1 px-3 flex items-center rounded-md text-sm',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.tgrit1.formatted,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-sm',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.tgrit2per3.formatted,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-sm',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.percent_contribution.formatted,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-sm',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.subtype_distribution.formatted,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-sm',
          bgColor: tkoData.background
        },
      ]
    }, 
    january: {
      value: '-',
      children: [
        {
          label: tkoData.calculation.january.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.january.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
    }, 
    february: {
      value: "-",
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.february.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.february.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
    }, 
    march: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.march.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.march.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
    }, 
    april: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.april.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.april.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
    }, 
    may: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.may.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.may.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
    }, 
    june: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.june.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.june.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
    }, 
    july: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.july.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.july.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
    }, 
    august: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.august.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.august.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
    }, 
    september: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.september.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.september.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
    }, 
    october: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.october.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.october.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
    }, 
    november: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.november.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.november.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm mr-[2px] p-3',
          bgColor: tkoData.background
        },
      ],
    }, 
    december: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-end gap-1',
      children: [
        {
          label: tkoData.calculation.december.number_vehicle.formatted,
          className: 'w-1/2 flex items-center p-3 justify-end text-center rounded-md text-sm ml-[2px] p-3',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.december.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end text-center rounded-md text-sm p-3',
          bgColor: tkoData.background
        },
      ],
    },
    key: parentIndex,
    type: `${key}-${parentIndex}`,
    className: `w-full px-3 py-1 rounded-md ${tkoData.background ? "" : "border-x-2"} border-white`
  }
}

export const assignTkoChildDataMidData = (tkoData, key, parentIndex) => {
  return { 
    description: {
      value: tkoData.type_distribution.formatted,
      className: 'w-full flex flex-row justify-center gap-1 text-xs',
      bgColor: tkoData.background ? tkoData.background : "#FFFFFF",
      children: [
        {
          label: tkoData.type_distribution.formatted,
          className: 'w-[70px] py-1 px-3 flex items-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.capacity.formatted,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.tgrit1.formatted,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.tgrit2per3.formatted,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.subtype_distribution.formatted,
          className: 'w-[70px] py-1 px-3 flex items-center rounded-md text-xs text-center',
          bgColor: tkoData.background
        },
        {
          label: tkoData.percent_contribution_subtype_distribution.formatted,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ]
    }, 
    january: {
      value: '-',
      children: [
        {
          label: tkoData.calculation.january.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.january.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
    }, 
    february: {
      value: "-",
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.february.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.february.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    }, 
    march: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.march.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.march.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    }, 
    april: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.april.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.april.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    }, 
    may: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.may.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.may.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    }, 
    june: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.june.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.june.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    }, 
    july: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.july.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.july.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    }, 
    august: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.august.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.august.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    }, 
    september: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.september.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.september.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    }, 
    october: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.october.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.october.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    }, 
    november: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.november.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.november.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    }, 
    december: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: tkoData.calculation.december.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
        {
          label: tkoData.calculation.december.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: tkoData.background
        },
      ],
    },
    key: parentIndex,
    type: `${key}-${parentIndex}`,
    className: `w-full px-3 py-1 rounded-md ${tkoData.background ? "" : "border-x-2"} border-white`
  }
}

export const assignTspcMidData = (tspcData, key, parentIndex) => {
  return { 
    description: {
      value: tspcData.description.value,
      className: 'text-sm',
      bgColor: tspcData.background ? tspcData.background : "#FFFFFF"
    }, 
    january: {
      value: '-',
      children: [
        {
          label: tspcData.january.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.january.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pr-[2px]',
    }, 
    february: {
      value: "-",
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px] pr-[2px]',
      children: [
        {
          label: tspcData.february.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.february.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    }, 
    march: {
      value: '-',
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px] pr-[2px]',
      children: [
        {
          label: tspcData.march.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.march.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    }, 
    april: {
      value: '-',
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px] pr-[2px]',
      children: [
        {
          label: tspcData.april.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.april.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    }, 
    may: {
      value: '-',
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px] pr-[2px]',
      children: [
        {
          label: tspcData.may.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.may.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    }, 
    june: {
      value: '-',
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px] pr-[2px]',
      children: [
        {
          label: tspcData.june.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.june.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    }, 
    july: {
      value: '-',
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px] pr-[2px]',
      children: [
        {
          label: tspcData.july.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.july.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    }, 
    august: {
      value: '-',
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px] pr-[2px]',
      children: [
        {
          label: tspcData.august.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.august.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    }, 
    september: {
      value: '-',
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px] pr-[2px]',
      children: [
        {
          label: tspcData.september.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.september.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    }, 
    october: {
      value: '-',
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px] pr-[2px]',
      children: [
        {
          label: tspcData.october.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.october.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    }, 
    november: {
      value: '-',
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px] pr-[2px]',
      children: [
        {
          label: tspcData.november.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.november.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    }, 
    december: {
      value: '-',
      className: 'absolute top-0 h-full w-full flex flex-row justify-center gap-1 pl-[2px]',
      children: [
        {
          label: tspcData.december.number_vehicle.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
        {
          label: tspcData.december.number_oncall.formatted,
          className: 'w-1/2 flex items-center justify-end p-3 rounded-md text-sm',
          bgColor: tspcData.background
        },
      ],
    },
    key: parentIndex,
    type: `${key}-${parentIndex}`,
    className: `w-full px-3 py-1 rounded-md ${tspcData.background ? "" : "border-x-2"} border-white`
  }
}

export const assignBaseData =  (pmData, parentIndex, separate = true) => {
  return { 
    description: {
      value: pmData.description.value,
      className: `text-sm ${pmData.bold ? "font-bold" : ""}`,
      bgColor: pmData.background ? pmData.background : "#FFFFFF"
    }, 
    january: {
      value: pmData.calculation.january.formatted,
      className: renderClassName(pmData, "january", separate),
      bgColor: getBgColor(pmData, 'january')
    }, 
    february: {
      value: pmData.calculation.february.formatted,
      className: renderClassName(pmData, "february", separate),
      bgColor: getBgColor(pmData, 'february')
    }, 
    march: {
      value: pmData.calculation.march.formatted,
      className: renderClassName(pmData, "march", separate),
      bgColor: getBgColor(pmData, 'march')
    }, 
    april: {
      value: pmData.calculation.april.formatted,
      className: renderClassName(pmData, "april", separate),
      bgColor: getBgColor(pmData, 'april')
    }, 
    may: {
      value: pmData.calculation.may.formatted,
      className: renderClassName(pmData, "may", separate),
      bgColor: getBgColor(pmData, 'may')
    }, 
    june: {
      value: pmData.calculation.june.formatted,
      className: renderClassName(pmData, "june", separate),
      bgColor: getBgColor(pmData, 'june')
    }, 
    july: {
      value: pmData.calculation.july.formatted,
      className: renderClassName(pmData, "july", separate),
      bgColor: getBgColor(pmData, 'july')
    }, 
    august: {
      value: pmData.calculation.august.formatted,
      className: renderClassName(pmData, "august", separate),
      bgColor: getBgColor(pmData, 'august')
    }, 
    september: {
      value: pmData.calculation.september.formatted,
      className: renderClassName(pmData, "september", separate),
      bgColor: getBgColor(pmData, 'september')
    }, 
    october: {
      value: pmData.calculation.october.formatted,
      className: renderClassName(pmData, "october", separate),
      bgColor: getBgColor(pmData, 'october')
    }, 
    november: {
      value: pmData.calculation.november.formatted,
      className: renderClassName(pmData, "november", separate),
      bgColor: getBgColor(pmData, 'november')
    }, 
    december: {
      value: pmData.calculation.december.formatted,
      className: renderClassName(pmData, "december", separate),
      bgColor: getBgColor(pmData, 'december')
    },
    key: parentIndex,
    type: parentIndex,
    className: `w-full px-3 py-1 rounded-md ${pmData.background ? "" : "border-x-2"} border-white`
  }
}

export const assignFlaggingData =  (pmData, parentIndex, separate = true) => {
  return { 
    description: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    january: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    february: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    march: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    april: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    may: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    june: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    july: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    august: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    september: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    october: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    november: {
      value: "",
      className: "",
      bgColor: ""
    }, 
    december: {
      value: "",
      className: "",
      bgColor: ""
    },
    key: parentIndex,
    type: parentIndex,
    className: ""
  }
}

export const assignBaseChildData = (baseChildData, parentIndex) => {
  return { 
    description: {
      value: baseChildData.type_distribution.value,
      className: 'w-full flex flex-row justify-center gap-1 text-xs',
      bgColor: baseChildData.background ? baseChildData.background : "#FFFFFF",
      children: [
        {
          label: baseChildData.type_distribution.value,
          className: 'w-[70px] py-1 px-3 flex items-center rounded-md text-xs',
          bgColor: baseChildData.type_distribution.background
        },
        {
          label: baseChildData.capacity.value,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.capacity.background
        },
        {
          label: baseChildData.tgrit1.value,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.tgrit1.background
        },
        {
          label: baseChildData.tgrit2per3.value,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.tgrit2per3.background
        },
        {
          label: baseChildData.subtype_distribution.value,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.subtype_distribution.background
        },
        {
          label: baseChildData.contribute_distribution.value,
          className: 'w-[70px] py-1 px-3 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.contribute_distribution.background
        },
      ]
    }, 
    january: {
      value: '-',
      children: [
        {
          label: baseChildData.january.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.january.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.january.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.january.number_vehicle.background
        },
      ],
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
    }, 
    february: {
      value: "-",
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.february.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.february.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.february.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.february.number_oncall.background
        },
      ],
    }, 
    march: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.march.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.march.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.march.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.march.number_oncall.background
        },
      ],
    }, 
    april: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.april.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.april.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.april.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.april.number_oncall.background
        },
      ],
    }, 
    may: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.may.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.may.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.may.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.may.number_oncall.background
        },
      ],
    }, 
    june: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.june.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.june.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.june.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.june.number_oncall.background
        },
      ],
    }, 
    july: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.july.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.july.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.july.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.july.number_oncall.background
        },
      ],
    }, 
    august: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.august.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.august.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.august.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.august.number_oncall.background
        },
      ],
    }, 
    september: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.september.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.september.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.september.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.september.number_oncall.background
        },
      ],
    }, 
    october: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.october.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.october.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.october.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.october.number_oncall.background
        },
      ],
    }, 
    november: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.november.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.november.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.november.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.november.number_oncall.background
        },
      ],
    }, 
    december: {
      value: '-',
      className: 'absolute top-0 left-0 h-full w-full flex flex-row justify-center p-[2px] gap-1',
      children: [
        {
          label: baseChildData.december.number_vehicle.value,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.december.number_vehicle.background
        },
        {
          label: <p className="text-trucne-pre-wrap">{baseChildData.december.number_oncall.value}</p>,
          className: 'w-1/2 flex items-center justify-center text-center rounded-md text-xs',
          bgColor: baseChildData.december.number_oncall.background
        },
      ],
    },
    key: parentIndex,
    type: parentIndex,
    className: `w-full px-3 py-1 rounded-md ${baseChildData.background ? "" : "border-x-2"} border-white`
  }
}