import routeUrl from "./routeUrl";

const { IconList } = require("assets/image");

const sidebarMenu = [
  {
    label: "Overview",
    menu: [
      {
        title: "Dashboard",
        icon: IconList.SidebarDashboard,
        route: routeUrl.dashboard
      },
    ]
  },
  {
    label: "BP SUPPORT",
    menu: [
      {
        title: "Master Data",
        icon: IconList.SidebarMasterData,
        route: routeUrl.masterData,
        child: [
          {
            title: "Country",
            route: routeUrl.masterDataCountry,
          },
          {
            title: "Branch",
            route: routeUrl.masterDataBranch,
          },
          {
            title: "Kurs USD",
            route: routeUrl.masterDataKurs,
          },
          {
            title: "Average Price",
            route: routeUrl.masterDataAveragePrice,
          },
          {
            title: "Sales Type",
            route: routeUrl.masterDataSalesType,
          },
          {
            title: "Sales Data",
            route: routeUrl.masterDataSalesData,
          },
          {
            title: "Financial Statement",
            route: routeUrl.masterDataFinancialStatment,
          },
          {
            title: "Cost Allocation",
            route: routeUrl.masterDataCostAllocation,
          },
          {
            title: "Salesman Type",
            route: routeUrl.masterDataSalesmanType,
          },
          {
            title: "Employee Level",
            route: routeUrl.masterDataEmployeeLevel,
          },
          {
            title: "Personnel",
            route: routeUrl.masterDataPersonnel,
          },
          {
            title: "Assign Personnel",
            route: routeUrl.masterDataAssignPersonnel,
          },
          {
            title: "Personnel Number",
            route: routeUrl.masterDataPersonnelNumber,
          },
          {
            title: "Target Salesman",
            route: routeUrl.masterDataTargetSalesman,
          },
          {
            title: "Omset Contribution",
            route: routeUrl.masterDataOmsetContribution,
          },
          // {
          //   title: "RO",
          //   route: routeUrl.masterDataRo,
          // },
          // {
          //   title: "Realisasi RO",
          //   route: routeUrl.masterDataRealisasiRp,
          // },
        ]
      },
      {
        title: "Warehouse",
        icon: IconList.SidebarWarehouse,
        route: routeUrl.warehouse,
        child: [
          {
            title: "Warehouse 1",
            route: routeUrl.warehouse1
          },
          {
            title: "Warehouse 2",
            route: routeUrl.warehouse2
          },
          {
            title: "Warehouse 3",
            route: routeUrl.warehouse3
          },
          {
            title: "Working Days",
            route: routeUrl.warehouseWorkingDays
          }
        ]
      },
      {
        title: "Vehicle",
        icon: IconList.SidebarVichles,
        route: routeUrl.vehicle,
        child: [
          {
            title: "Salesman Proportion",
            route: routeUrl.salesmanProportion,
          },
          {
            title: "Vehicle Type",
            route: routeUrl.vehicleType,
          },
          {
            title: "Vehicle Master",
            route: routeUrl.vehicleMaster,
          },
        ]
      },
      {
        title: "Expense",
        icon: IconList.SidebarExpanse,
        route: routeUrl.expense,
        child: [
          {
            title: "Employee Expense",
            route: routeUrl.employeeExpense,
          },
          {
            title: "Officer Expense",
            route: routeUrl.officerExpense,
          },
          {
            title: "Utility Expense",
            route: routeUrl.utilityExpense,
          },
          {
            title: "Master Data Expense",
            route: routeUrl.masterDataExpense,
          },
        ]
      },
      {
        title: "Reports",
        icon: IconList.SidebarReports,
        route: routeUrl.reports,
        child: [
          {
            title: "Warehouse Calculation",
            route: routeUrl.reportsWarehouseCalculation,
          },
          {
            title: "Vehicle Calculation",
            route: routeUrl.reportVehicleCalculation,
          },
          {
            title: "Financial Statement",
            route: routeUrl.reportsFinancialStatment1,
          },
          {
            title: "Salesman Calculation",
            route: routeUrl.reportSalesmanCalculation,
          },
          // {
          //   title: "Add Cost Component",
          //   route: "",
          // },
          // {
          //   title: "Report RO",
          //   route: "",
          // },
        ]
      },
    ]
  },
  
]

export default sidebarMenu;