export const subTypeDist = [
  {
    label: "TO Grosir",
    value: "TO Grosir"
  },
  {
    label: "TO Reguler Inap",
    value: "TO Reguler Inap"
  },
  {
    label: "TO Reguler Non Inap",
    value: "TO Reguler Non Inap"
  },
  {
    label: "TO Mix Inap",
    value: "TO Mix Inap"
  },
  {
    label: "TO Mix Non Inap",
    value: "TO Mix Non Inap"
  },
  {
    label: "Canvass Reguler",
    value: "Canvass Reguler"
  },
  {
    label: "Canvass Inap",
    value: "Canvass Inap"
  },
]