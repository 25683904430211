import React, { useState } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { IconList } from "assets/image";
import "react-datepicker/dist/react-datepicker.css";
import "./InputRange.scss";
import { range } from "lodash";

const Menu = props => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 12,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        width: '365px',
        overflow: 'hidden',
      }}
      {...props}
    />
  );
};

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div className="relative border-[1px] border-[#BBBBBB] rounded-md">
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {/* {isOpen ? <div className="top-0 left0 fixed z-[1]" onClick={onClose} /> : null} */}
  </div>
); 

const years = range(1990, dayjs(new Date()).year() + 1, 1);
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const locale = {
  localize: {
    day: (n) => days[n],
    month: (n) => months[n],
  },
  formatLong: {
    date: () => "mm/dd/yyyy",
  },
};

const InputRange = ({ data, startDate, onChange, endDate, maxDate }) => {
  const [isOpen, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  const handleOnChange = (value) => {
    let formatValue = value
    if(value[1]) {
      if(!dayjs(value[0]).isSame(value[1], "month")) {
        formatValue = [value[1], null]
      } else if(dayjs(value[0]).isSame(value[1], "day")) {
        formatValue = [value[0], null]
      }
    }

    const dateData = {
      target: {
        type: data.type,
        name: data.name,
        value: formatValue
      }
    }
    onChange(dateData)
  }

  const renderRange = () => {
    const range = Math.abs(dayjs(data.value[0]).diff(data.value[1], 'days')) + 1
    let month = dayjs(data.value[0]).format("MMMM")
    if(data.value[1]) {
      if(!dayjs(data.value[0]).isSame(data.value[1], "month")) {
        month = data.value.map(result => dayjs(result).format("MMMM")) 
      }
    }
    return `${month} (${range} Days)`
  }

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <button
          disabled={isOpen}
          onClick={toggleOpen}
          className="w-full text-left px-3 py-2 flex items-center justify-between"
        >

          <span className={data.value[1] ? "text-black" : "text-[#AAAAAA]" }>
            {data.value[1] ? renderRange() : data.placeholder}
          </span>
          <img src={IconList.ChevronDownGray} alt="" />
        </button>
      }
    >
      <DatePicker
        className="shadow-higher"
        minDate={dayjs(new Date()).subtract(30, 'days')}
        // selected={startDate}
        onChange={handleOnChange}
        startDate={data.value[0]}
        endDate={data.value[1]}
        selectsRange
        inline
        locale={locale}
        // maxDate={maxDate ? new Date(maxDate) : null}
        onClickOutside={toggleOpen}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
        }) => (
          <div
            className="flex w-full flex-row"
            style={{
              margin: 10,
            }}
          >
            <select
              className="text-xl"
              value={months[dayjs(date).month()]}
              onChange={e => changeMonth(months.indexOf(e.target.value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className="text-xl"
              value={dayjs(date).year()}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
      />
    </Dropdown>
  );
}

InputRange.propTypes = {
  data: PropTypes.object,
};

InputRange.defaultProps = {
  data: {
    title: "",
    placeholder: "",
    type: "",
    onChange: () => {},
    value: null,
    options: [
      { value: "", label: "" }
    ],
    onSelectSearch: null // for async process
  }
};

export default InputRange;
