const routeUrl = {
  home: "/",
  dashboard: "/dashboard",
  
  masterData: "/master-data",
  masterDataCountry: "/master-data/country",
  masterDataBranch: "/master-data/branch",
  masterDataKurs: "/master-data/kurs-usd",
  masterDataAveragePrice: "/master-data/average-price",
  masterDataSalesType: "/master-data/sales-type",
  masterDataSalesData: "/master-data/sales-data",
  masterDataFinancialStatment: "/master-data/financial-statement",
  masterDataCostAllocation: "/master-data/cost-allocation",
  masterDataSalesmanType: "/master-data/salesman-type",
  masterDataEmployeeLevel: "/master-data/employee-type",
  masterDataPersonnel: "/master-data/personnel",
  masterDataAssignPersonnel: "/master-data/assign-personnel",
  masterDataPersonnelNumber: "/master-data/personnel-number",
  masterDataTargetSalesman: "/master-data/target-salesman",
  masterDataOmsetContribution: "/master-data/omset-contribution",
  masterDataRo: "/master-data/ro",
  masterDataRealisasiRp: "/master-data/realisasi-ro",

  warehouse: "/warehouse",
  warehouse1: "/warehouse/warehouse-1",
  warehouse2: "/warehouse/warehouse-2",
  warehouse3: "/warehouse/warehouse-3",
  warehouseWorkingDays: "/warehouse/warehouse-working-days",

  vehicle: "/vehicle",
  salesmanProportion: "/vehicle/salesman-propotion",
  vehicleType: "/vehicle/vehicle-type",
  vehicleMaster: "/vehicle/vehicle-master",

  expense: "/expense",
  employeeExpense: "/expense/employee-expense",
  officerExpense: "/expense/officer-expense",
  utilityExpense: "/expense/utility-expense",
  masterDataExpense: "/expense/master-data-expense",

  reports: "/reports",
  reportsWarehouseCalculation: "/reports/warehouse-calculation",
  reportsFinancialStatment1: "/reports/financial-statmentment-1",
  reportSalesmanCalculation: "/reports/salesman-calculation",
  reportVehicleCalculation: "/reports/vehicle-calculation",

  authentication: "/authentication"
};

export default routeUrl;