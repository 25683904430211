import React, { lazy } from "react";
import RouteHandler from "helper/routeHandler";
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes } from "react-router-dom";
import { routeUrl } from "constant";
const AuthPage = lazy(() => import("page/authentication"))

function App() {
  return (
    <div className="w-full h-full">
      <Routes>
        <Route path={routeUrl.authentication} exact element={<AuthPage/>} />
      </Routes>
      <RouteHandler />
      <ToastContainer />
    </div>
  );
}

export default App;
