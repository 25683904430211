import React from 'react';
import Table from 'rc-table';
import PropTypes from 'prop-types';
import './warehouseCalculationTable.scss';
import { TableLoading } from 'components';


const SalesmanCalculationTable = ({tableData, tableColumns, descData, isLoading}) => {
  return (
    <TableLoading isLoading={isLoading}>
      <div className="p-4 h-full flex flex-col relative">
        <div className="flex flex-row p-1 gap-1">
          <div className="bg-red-100 w-[323px] p-3 rounded-md flex justify-between text-sm">
            <span>Avg Price / Cases (USD)</span>
            <b>{descData.avg}</b>
          </div>
          <div className="bg-red-100 w-[323px] p-3 rounded-md flex justify-between text-sm">
            <span>Kurs {descData.exchange.from} to {descData.exchange.to}</span>
            <b>{descData.exchange.value}</b>
          </div>
          <div className="bg-red-100 w-[323px] p-3 rounded-md flex justify-between text-sm">
            <span>EDD</span>
            <b>{descData.edd}</b>
          </div>
        </div>
        <Table 
          className='warehouse-calculation-table'
          columns={tableColumns}
          scroll={{ x: 1650, y: null}}
          data={tableData}
          style={{ width: null}}
        />
      </div>
    </TableLoading>
  );
};

SalesmanCalculationTable.propTypes = {
  tableColumns: PropTypes.array,
  tableData: PropTypes.array,
  descData: PropTypes.object
 
};

SalesmanCalculationTable.defaultProps = {
  tableColumns: [],
  tableData: [],
  descData: {
    avg: "",
    edd: "",
    exchange: {
      currency: "",
      value: 0
    }
  }
};


export default SalesmanCalculationTable;