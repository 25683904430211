/* eslint-disable jsx-a11y/no-redundant-roles */
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import { IconList } from 'assets/image'
import BaseDropdown from 'components/Atoms/Dropdown/baseDropdown'
import "./custom.scss"
import { removeCookies } from 'helper/functions'

const solutions = [
  {
    name: 'Analytics',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '#',
    icon: CursorClickIcon,
  },
  { name: 'Security', description: "Your customers' data will be safe and secure.", href: '#', icon: ShieldCheckIcon },
  {
    name: 'Integrations',
    description: "Connect with third-party tools that you're already using.",
    href: '#',
    icon: ViewGridIcon,
  },
  {
    name: 'Automations',
    description: 'Build strategic funnels that will drive your customers to convert',
    href: '#',
    icon: RefreshIcon,
  },
]
 
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#',
    icon: SupportIcon,
  },
  {
    name: 'Guides',
    description: 'Learn how to maximize our platform to get the most out of it.',
    href: '#',
    icon: BookmarkAltIcon,
  },
  {
    name: 'Events',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon,
  },
  { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#', icon: ShieldCheckIcon },
]

export default function Navbar({ handleCollapsedChange }) {
  const itemsPerpage = [
    {
      type: "button",
      label: "Account Settings",
      icon: IconList.SettingProfile,
      onClick: () => console.log("Clicked")
    },
    {
      type: "button",
      label: "Company List",
      icon: IconList.CompanyList,
      onClick: () => console.log("Clicked")
    },
    {
      type: "button",
      label: "Change Language",
      icon: IconList.ChangeLanguage,
      onClick: () => console.log("Upload Template")
    },
    {
      type: "button",
      label: "Logout",
      icon: IconList.Logout,
      onClick: () => {
        removeCookies("isLogin", "/")
        window.location.reload()
      }
    }
  ]

  return (
    <Popover className="relative bg-white">
      <div className="mx-auto px-6 py-4">
        <div className="flex justify-between items-center md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <button onClick={handleCollapsedChange}>
              <img
                className="h-6 w-auto"
                src={IconList.GridMenu}
                alt=""
              />
            </button>
            <div as="nav" className="hidden items-center md:flex space-x-6 ml-5">
              <a href="https://github.com/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Home
              </a>
              <a href="https://github.com/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Marketing
              </a>
              <a href="https://github.com/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Sales
              </a>
              <a href="https://github.com/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Sales
              </a>
              <a href="https://github.com/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Logistic
              </a>
              <a href="https://github.com/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Finance
              </a>
              <a href="https://github.com/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                BP Support
              </a>
              <a href="https://github.com/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                eDot
              </a>
            </div>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex items-center justify-end lg:w-0">
            <BaseDropdown 
              addClassName="button-profile"
              menuPossition="right-0" 
              menuMaxHeight="h-max"
              menuWidth="z-10 w-[200px]"
              customHeader={
                <div className="flex flex-row border-b-2 mb-2 py-3">
                  <img src={IconList.DefaultProfile} alt="" />
                  <div className="flex flex-col ml-1">
                    <span className="text-md">Gwen Stacy</span>
                    <span className="text-sm">Admin</span>
                  </div>
                </div>
              }
              title={
                <div className="flex flex-row items-center">
                  <span className="rounded-full bg-blue-400 p-2 text-xs mr-1">GS</span>
                  <span className="text-md">Gwen Stacy</span>
                </div>
              }
              dropdownContent={itemsPerpage} 
              icon={IconList.ChevronDownGray} 
            />
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Workflow"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                      <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a href="https://github.com/" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Pricing
                </a>

                <a href="https://github.com/" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Docs
                </a>
                {resources.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div>
                <a
                  href="https://github.com/"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Sign up
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?{' '}
                  <a href="https://github.com/" className="text-indigo-600 hover:text-indigo-500">
                    Sign in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}