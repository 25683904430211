import React, {useEffect} from "react";
import { useNavigate} from "react-router-dom";
import { routeUrl } from "constant";
import MainLayout from "components/Organism/Layout";
import { getCookies } from "./functions";

// const AuthPage = lazy(() => import("page/authentication"))

const RouteHandler = () => {
  const isLogin = getCookies("isLogin")
  const navigate = useNavigate()


  useEffect(() => {
    !isLogin && navigate(routeUrl.authentication)
  }, [isLogin, navigate])

  return isLogin && <MainLayout />
}

export default RouteHandler;