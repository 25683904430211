import React from 'react';
import Table from 'rc-table';
import './financialStatment1Table.scss';
import { TableLoading } from 'components';

const FinancialStatment1Table = ({tableData, tableColumns, isLoading}) => {
  return (
    <TableLoading isLoading={isLoading}>
      <div className="p-4 h-full flex flex-col relative">
        <Table 
          className='financial-calculation-table'
          columns={tableColumns}
          scroll={{ x: 1650, y: null}}
          data={tableData}
          style={{ width: null}}
        />
      </div>
    </TableLoading>
  );
};

export default FinancialStatment1Table;