import { IconList } from 'assets/image';
import { toast } from 'react-toastify';
import "./toast.scss"

const toastHook = {
  error: (message = "Something Wrong!") => {
    toast.error(message, {
      className: "toast-error-container text-white",
      autoClose: 3000,
      position: "top-center",
      icon: false,
      closeButton: <img src={IconList.CloseIconWhite} alt="" />
    })
  },
  success: (message = "Success!") => {
    toast.success(message, {
      className: "toast-success-container text-white",
      autoClose: 3000,
      position: "top-center",
      icon: false,
      closeButton: <img src={IconList.CloseIconWhite} alt="" />
    })
  }
}

export default toastHook
