import PropTypes from 'prop-types';
import "./loadingTable.scss";

const LoadingTable = ({children, isLoading}) => {
  return (
    <div className="w-full h-full relative">
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center absolute top-0 left-0 z-[2] bg-white">
          <h1 className="mr-2 text-lg textspa tracking-[2px]">Loading...</h1>
          <span className="loader"></span>
        </div>
      )}
      {children}
    </div>
  )
}

LoadingTable.propTypes = {
  children: PropTypes.element,
  isLoading: PropTypes.bool
};

LoadingTable.defaultProps = {
  children: <div></div>,
  isLoading: false
};


export default LoadingTable