import apiList from 'constant/apiUrl';
import Api from 'helper/Api';
import { downloadFile, objectToQueryString } from 'helper/functions';
import toastHook from 'helper/Hook/toastr';
import _ from 'lodash';

// Get list
export const getListReportFinancialStatment = (filter, type) => {
	// console.log(filter)
	let cloneFilter = _.cloneDeep(filter)
	cloneFilter.month = new Date(cloneFilter.month).getMonth() + 1;
		 
	return Api().get(apiList.report.financialCalculation, {
		params: cloneFilter
		// {
		// 	// order: "updatedAt:desc",
		// 	company_id: "EM00", 
		// 	year: "2021",
		// 	plant_id: "BW04",
		// 	month: "6",
		// }
	})
	.then(result => {
		const returnData = result.data.data
		let mainData = []
		let childData = {}

		const renderClassName = (item, field) => {
			let addedClassname = ""
			if(item.background) {
				if(field === "january") {
					addedClassname = "border-l-2 rounded-l-md rounded-r-none"
				} else if (field === "description") {
					addedClassname = "rounded-md"
				} else if (field === "code") {
					addedClassname = "rounded-l-md rounded-r-none"
				} else if(field === "std2") {
					addedClassname = "border-r-2 rounded-r-md rounded-l-none"
				} else {
					addedClassname = "rounded-none border-l-0"
				}
			}
			
			return `text-sm word-break-word ${item.bold ? "font-bold" : ""} ${addedClassname}`
		}

		const renderValue = (value) => {
			return value !== "" ? value : "-"
		}
		

		if(!_.isEmpty(returnData)) {
			returnData.forEach((item, index) => {
				mainData.push({ 
					description: {
						value: renderValue(item.description.formatted),
						className: `text-sm ${item.bold ? "font-bold" : ""}`,
						bgColor: item.background ? item.background : "#FFFFFF"
					}, 
					added_salesman_value: {
						value: renderValue(item.added_salesman.value.formatted),
						className: renderClassName(item, ""),
						bgColor: item.background,
					},
					added_salesman_percent: {
						value: renderValue(item.added_salesman.percent.formatted),
						className: renderClassName(item, ""),
						bgColor: item.background,
					},
					bp_value: {
						value: renderValue(item.bp.value.formatted),
						className: renderClassName(item, ""),
						bgColor: item.background,
					}, 
					bp_percent: {
						value: renderValue(item.bp.percent.formatted),
						className: renderClassName(item, ""),
						bgColor: item.background,
					}, 
					actual_value: {
						value: renderValue(item.actual.value.formatted),
						className: renderClassName(item, ""),
						bgColor: item.background,
					}, 
					actual_percent: {
						value: renderValue(item.actual.percent.formatted),
						className: renderClassName(item, ""),
						bgColor: item.background,
					}, 
					bep_value: {
						value: renderValue(item.bep.value.formatted),
						className: renderClassName(item, ""),
						bgColor: item.background,
					}, 
					bep_percent: {
						value: renderValue(item.bep.percent.formatted),
						className: renderClassName(item, ""),
						bgColor: item.background,
					}, 
					actual_vs_target: {
						value: renderValue(item.actual_vs_target.formatted),
						className: renderClassName(item, ""),
						bgColor: item.background,
					}, 
					actual_vs_bep: {
						value: renderValue(item.actual_vs_bep.formatted),
						className: renderClassName(item, "std2"),
						bgColor: item.background,
					}, 
					code: {
						value: renderValue(item.code.formatted),
						className: renderClassName(item, "code"),
						bgColor: item.background,
					}, 
					key: index,
					className: `w-full px-3 py-1 rounded-md ${item.background ? "" : "border-x-2"} border-white`
				})
			});
			Object.keys(returnData).forEach(function(key, parentIndex) {
				
			});
		} else {
			toastHook.error("Oops! data is empty!")
		}
		
		// console.log(mainData)
		// console.log(childData)
		if(type === "sync") {
			toastHook.success("Sync data successfully!")
		}
		return {mainData, childData}

	})
	.catch(err => {
		if(type === "sync") {
			toastHook.error("Oops! unable to sync data. try again")
		} else {
			toastHook.error(err.message)
		}
		return err
	})
}

export const downloadFinancialStatementReport = (filter) => {
	let cloneFilter = _.cloneDeep(filter)	
	delete cloneFilter.month

	return downloadFile(apiList.report.vehicleCalculation+`/export${objectToQueryString(cloneFilter)}`, "financial_statement")
}