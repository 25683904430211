/* eslint-disable react-hooks/exhaustive-deps */
import { IconList } from "assets/image"
import BaseButton from "components/Atoms/Button/basicButton";
import BaseDropdown from "components/Atoms/Dropdown/baseDropdown"
import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types';
import _ from "lodash";

const itemsPerpage = [10, 20, 30, 40, 50]

const PaginationTable = ({itemMin, itemMax, totalPage, totalRecord, paginationCallback}) => {
  const [stateTotalPage, setTotalPage] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPerPage, setCurrentPerPage] = useState(10)

  useEffect(() => {
    let pageCount = []
    for (let i = 1; i <= totalPage; i++) {
      pageCount.push(i)
    }
    setTotalPage(pageCount)
  }, [totalPage])

  const handleChangePage = (type) => {
    let cloneCurrentPage = _.cloneDeep(currentPage)
    if(type === "next" && currentPage < totalPage) {
      cloneCurrentPage = cloneCurrentPage + 1
    } else if(type === "prev" && cloneCurrentPage > 1) {
      cloneCurrentPage = cloneCurrentPage-1
    } else if(!_.isString(type)) {
      cloneCurrentPage = type
    }

    setCurrentPage(cloneCurrentPage)
    paginationCallback({
      currentPage: cloneCurrentPage,
      currentPerPage: currentPerPage 
    })
  }

  const handleChangePerpage = (e) => {
    setCurrentPerPage(e)
    paginationCallback({
      currentPage: currentPage,
      currentPerPage: e 
    })
  }

  return (
    <section className="flex w-full md:flex-row flex-col text-[14px] mt-2">
      <div className="flex flex-row flex-1 items-center gap-x-2">
        <span>Items per page</span>
        <BaseDropdown 
          title={currentPerPage} 
          icon={IconList.CheveronBottomGray} 
          addClassName="custom-pagination-dropdown"
          menuPossition="bottom-0 left-0"
          dropdownContent={itemsPerpage}
          callbackOnChange={e => handleChangePerpage(e)}
        />
        <span>Showing {itemMin} - {itemMax} of {totalRecord} items</span>
      </div>
      <div className="flex flex-row-reverse flex-1 items-center gap-x-2">
        <BaseButton 
          icon={IconList.CheveronBottomGray} 
          addClasses="custom-next-page-button"
          onClick={() => handleChangePage("next")}
        />
        <BaseButton 
          icon={IconList.CheveronBottomGray} 
          addClasses="custom-prev-page-button"
          onClick={() => handleChangePage("prev")}
        />
        <span>of {totalPage} Pages</span>
        {stateTotalPage.length !== 0 && (
          <BaseDropdown 
            title={currentPage}
            icon={IconList.CheveronBottomGray} 
            addClassName="custom-pagination-dropdown"
            menuPossition="bottom-0 left-0"
            menuWidth="w-[40px]"
            dropdownContent={stateTotalPage}
            callbackOnChange={e => handleChangePage(e)}
          />
        )}
      </div>
    </section>
  )
}

PaginationTable.propTypes = {
  totalPage: PropTypes.number,
  totalRecord: PropTypes.number,
  paginationCallback: PropTypes.func
};

PaginationTable.defaultProps = {
  totalPage: 0,
  totalRecord: 0,
  paginationCallback: () => {}
};

export default PaginationTable