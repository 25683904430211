import apiList from 'constant/apiUrl';
import dayjs from 'dayjs';
import Api from 'helper/Api';
import { downloadFile, objectToQueryString } from 'helper/functions';
import toastHook from 'helper/Hook/toastr';

// Get list
export const getListEmployeeExpense = (filter, type) => {
	return Api().get(apiList.expense.employeeExpense, {
		params: {
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage
		}
	})
	.then(result => {
		const returnData = result.data.data
		const data = returnData.records.map(result => {
			return { 
				id: result.id,
				key: result.id,
				isChecked: false,
				company_id: result.company.company_id,
				company_description: result.company.description,
				employee_expense_type: result.employee_expense_type,
				year: result.year,
				description: result.description,
				plant_id: result.plant_id,
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		if(type === "sync") {
			toastHook.success("Sync data successfully!")
		}
		return {data, pagging}
	})
	.catch(err => {
		if(type === "sync") {
			toastHook.error("Oops! unable to sync data. try again")
		}
	})
}

// Add or Edit
export const createListEmployeeExpense = (postdata, methodType = "POST", id) => {
	const postDataObj = {}
	const apiUrl = id ? apiList.expense.employeeExpense + "/" + id : apiList.expense.employeeExpense 

	for (let i = 0; i < postdata.length; i++) {
		const element = postdata[i];
		if(element.type === "select") {
			postDataObj[element.name] = element.value.value
		} else if(element.type === "date-year") {
			postDataObj[element.name] = dayjs(element.value).format("YYYY")
		} else {
			postDataObj[element.name] = element.value
		}
	}

	return Api()({
		url: apiUrl,
		data: postDataObj,
		method: methodType,
	})
	.then(result => {
		return result
	})
	.catch(err => {
		console.log(err.response.data.message)
		toastHook.error(err.response.data.message)
		return {status: err.response.status}
	})
}

// Delete
export const deleteListEmployeeExpense = (ids) => {
	return Api().patch(apiList.expense.employeeExpense+`/delete`,{
			permanent: false,
			id: ids
		})
	.then(result => {
		const status = result.status
		
		return status
	})
	.catch(err => toastHook.error(err.message))
}

// Upload
export const uploadListemployeeExpense = (files) => {
	let data = new FormData();
	files.forEach(file => {
		data.append('file', file, file.name);
	});

	return Api().post(apiList.expense.employeeExpense+`/import`, data , {
		onUploadProgress: progressEvent => console.log(progressEvent.loaded)
	})
	.then(result => {
		// const status = result.status
		toastHook.success(result.data.message)
		return {
			status: 200
		}
	})
	.catch(err => {
		toastHook.error(err.response.data.message)
		return {
			status: 400
		}
	})
}

// Export
export const downloadListEmployeeExpense = (filter) => {
	const params = {
		order: "updatedAt:desc",
		search: filter.search, 
		limit: filter.currentPerPage,
		page: filter.currentPage
	}

	return downloadFile(apiList.masterData.employeeExpense+`/export${objectToQueryString(params)}`, "employeeExpense_export")
}

export const downloadTemplateListEmployeeExpense = () => {
	return downloadFile(apiList.expense.employeeExpense+"/template", "employeeExpense_template")
}