import React, { useState } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { IconList } from "assets/image";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.scss";

const Menu = props => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 12,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 4,
        width: '365px',
        overflow: 'hidden',
        padding: "0px 10px"
      }}
      {...props}
    />
  );
};

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div className="relative border-[1px] border-[#BBBBBB] rounded-md">
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {/* {isOpen ? <div className="top-0 left0 fixed z-[1]" onClick={onClose} /> : null} */}
  </div>
); 

const days = ["M", "S", "S", "R", "K", "J", "S"];
const months = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const locale = {
  localize: {
    day: (n) => days[n],
    month: (n) => months[n],
  },
  formatLong: {
    date: () => "mm/dd/yyyy",
  },
};

const InputYear = ({ data, startDate, onChange, endDate, maxDate }) => {
  const [isOpen, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  const handleOnChange = (value) => {
    const dateData = {
      target: {
        type: data.type,
        name: data.name,
        value: value
      }
    }
    toggleOpen()
    onChange(dateData)
  }

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <button
          disabled={isOpen}
          onClick={toggleOpen}
          className="w-full text-left px-3 py-2 flex items-center justify-between"
        >

          <span className={data.value ? "text-black" : "text-[#AAAAAA]" }>{data.value ? dayjs(data.value).format("YYYY") : data.placeholder}</span>
          <img src={IconList.ChevronDownGray} alt="" />
        </button>
      }
    >
      <DatePicker
        className="shadow-higher"
        selected={data.value}
        onChange={handleOnChange}
        startDate={data.value}
        endDate={endDate}
        inline
        locale={locale}
        showYearPicker
        yearItemNumber={16}
        onClickOutside={toggleOpen}
        maxDate={maxDate ? new Date(maxDate) : null}
        renderCustomHeader={({
          date,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          increaseYear,
          decreaseYear
        }) => (
          <div
            className="flex w-full flex-row" 
            style={{
              margin: "0px 10px",
              justifyContent: "center",
            }}
          >
            <button
              type="button"
              onClick={decreaseYear}
              disabled={prevMonthButtonDisabled}
              className="flex flex-row items-center justify-center"
            >
              <img
                src={IconList.CheveronRightBlue}
                alt=""
                className="rotate-180 w-2"
              />
            </button>
            <h3 className="font-roboto font-medium text-[24px] flex-1 uppercase">
              {dayjs(data.value).format("YYYY")}
            </h3>
            <button
              type="button"
              onClick={increaseYear}
              disabled={nextMonthButtonDisabled}
              className="flex flex-row items-center justify-center mr-5"
            >
              <img src={IconList.CheveronRightBlue} alt="" className="w-2" />
            </button>
          </div>
        )}
      />
    </Dropdown>
  );
}

InputYear.propTypes = {
  data: PropTypes.object,
};

InputYear.defaultProps = {
  data: {
    title: "",
    placeholder: "",
    type: "",
    onChange: () => {},
    value: null,
    options: [
      { value: "", label: "" }
    ],
    onSelectSearch: null // for async process
  }
};

export default InputYear;
