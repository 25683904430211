/* eslint-disable react-hooks/exhaustive-deps */
// import { IconList } from "assets/image";
import { ContentHeader } from "components";
import _ from "lodash";
import FilterMasterDataExpense from "./filter/filter";
import React, {useState} from "react";
import { downloadFinancialStatementReport, getListReportFinancialStatment } from "module/reports/financialStatment/action";
import FinancialStatment1Table from "components/Molecules/Table/financialStatment1Table";
import toastHook from "helper/Hook/toastr";

const FinancialStatment1 = () => {
  const [originData, setOriginData] = useState([])
  const [childData, setChildData] = useState([])
  const [isExpand, setIsExpand] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false)
  const [filter, setFilter] = useState({
    company_id: "",
    year: new Date(),
    plant_id: "",
    month: new Date().setMonth(0),
  })

  // get EmployeeExpense list
  React.useEffect(() => {
    // getFinancialCalculation()
    if(filter.company_id !== "") {
      getFinancialCalculation()
    }
  }, [filter])

  const getFinancialCalculation = (type) => {
    setLoadingTable(true)
    getListReportFinancialStatment(filter, type)
    .then(result => {
      setLoadingTable(false)
      setOriginData(result.mainData)
      setChildData(result.childData)
    })
  }

  const handleFilterData = (data) => {
    let cloneFilter = _.cloneDeep(filter)
    cloneFilter = data
    setFilter(cloneFilter)
  }

  const columns = [
    { 
      title: (
        <button className="flex flex-row w-full items-center justify-between p-3" onClick={() => handleExpand()}>
          <span>Description</span>
        </button>
      ), 
      dataIndex: 'description', 
      key: 'description', 
      width: 200, 
      fixed: 'left',
      className: 'th-p-0 bordered-th font-medium z-[1] sticky left-0'
      // h-[50px]
      ,
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className}`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="flex h-[75px] flex-row w-full items-center justify-center p-3 relative">
          <div className="flex w-full items-center justify-center text-center h-full p-3 border-r border-gray-400 m-0 absolute top-0 left-0">
            <span>Code</span>
          </div>
        </div>
      ), 
      dataIndex: 'code', 
      key: 'code', 
      width: 50,
      fixed: 'left',
      className: 'th-p-0 bordered-top-left border-r-0 font-medium z-[1] sticky',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-center rounded border-x-0 text-center`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-full text-center h-full p-3 border-b border-r border-gray-400 m-0">
          SALESMAN
        </div>
      ),
      children: [
        { 
          title: (
            <div className="w-full h-full text-center border-r border-gray-400 py-1 font-normal">
              Value
            </div>
          ), 
          dataIndex: 'added_salesman_value', 
          key: 'added_salesman_value', 
          width: 90, 
          className: 'th-p-0 font-medium text-right-force relative',
          render: (row, record) => {
            return (
              <div className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end border-[3px] border-b-0 border-r-0`} 
                style={{backgroundColor: row.bgColor}}>
                {row.value}
              </div>
            )
          }
        },
      ],
      dataIndex: 'salesman', 
      key: 'salesman', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      // render: (row, record) => {
      //   return (
      //     <div 
      //       className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
      //       style={{backgroundColor: row.bgColor}}>
      //       {row.value}
      //     </div>
      //   )
      // }
    },
    { 
      title: (
        <div className="w-full text-center h-full p-3">
          %
        </div>
      ),
      dataIndex: 'added_salesman_percent', 
      key: 'added_salesman_percent', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      width: 50,
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-full text-center h-full p-3 border-b border-x border-gray-400">
          BP EDD
        </div>
      ),
      children: [
        { 
          title: (
            <div className="w-full h-full border-x border-gray-400 py-1 font-normal">
              Value
            </div>
          ), 
          dataIndex: 'bp_value', 
          key: 'bp_value', 
          width: 90, 
          className: 'th-p-0 font-medium text-center relative',
          render: (row, record) => {
            return (
              <div className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end border-[3px] border-b-0 border-r-0`} 
                style={{backgroundColor: row.bgColor}}>
                {row.value}
              </div>
            )
          }
        },
      ],
      dataIndex: 'bp_value', 
      key: 'bp_value', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      // render: (row, record) => {
      //   return (
      //     <div 
      //       className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
      //       style={{backgroundColor: row.bgColor}}>
      //       {row.value}
      //     </div>
      //   )
      // }
    },
    { 
      title: (
        <div className="w-full text-center h-full p-3">
          %
        </div>
      ),
      dataIndex: 'bp_percent', 
      key: 'bp_percent', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      width: 50,
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-full text-center h-full p-3 border-b border-x border-gray-400">
          ACTUAL
        </div>
      ),
      children: [
        { 
          title: (
            <div className="w-full h-full border-x border-gray-400 py-1 font-normal">
              Value
            </div>
          ), 
          dataIndex: 'actual_value', 
          key: 'actual_value', 
          width: 90, 
          className: 'th-p-0 font-medium text-center relative',
          render: (row, record) => {
            return (
              <div className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end border-[3px] border-b-0 border-r-0`} 
                style={{backgroundColor: row.bgColor}}>
                {row.value}
              </div>
            )
          }
        },
      ],
      dataIndex: 'actual_value', 
      key: 'actual_value', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      // render: (row, record) => {
      //   return (
      //     <div 
      //       className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
      //       style={{backgroundColor: row.bgColor}}>
      //       {row.value}
      //     </div>
      //   )
      // }
    },
    { 
      title: (
        <div className="w-full text-center h-full p-3">
          %
        </div>
      ),
      dataIndex: 'actual_percent', 
      key: 'actual_percent', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      width: 50,
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    { 
      title: (
        <div className="w-full text-center h-full p-3 border-b border-x border-gray-400">
          BEP
        </div>
      ),
      children: [
        { 
          title: (
            <div className="w-full h-full border-x border-gray-400 py-1 font-normal">
              Value
            </div>
          ), 
          dataIndex: 'bep_value', 
          key: 'bep_value', 
          width: 90, 
          className: 'th-p-0 font-medium text-center relative',
          render: (row, record) => {
            return (
              <div className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end border-[3px] border-b-0 border-r-0`} 
                style={{backgroundColor: row.bgColor}}>
                {row.value}
              </div>
            )
          }
        },
      ],
      dataIndex: 'bep_value', 
      key: 'bep_value', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      // render: (row, record) => {
      //   return (
      //     <div 
      //       className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
      //       style={{backgroundColor: row.bgColor}}>
      //       {row.value}
      //     </div>
      //   )
      // }
    },
    { 
      title: (
        <div className="w-full text-center h-full p-3">
          %
        </div>
      ),
      dataIndex: 'bep_percent', 
      key: 'bep_percent', 
      className: 'th-p-0 bordered-top font-medium text-right-force relative',
      width: 50,
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
    // { 
    //   title: (
    //     <div className="w-full h-[75px] flex items-center justify-center p-3 border-l border-gray-400">
    //       Value
    //     </div>
    //   ),
    //   width: 130,
    //   dataIndex: 'actual_vs_target', 
    //   key: 'actual_vs_target', 
    //   className: 'th-p-0 bordered-top font-medium text-right-force relative',
    //   render: (row, record) => {
    //     return (
    //       <div 
    //         className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
    //         style={{backgroundColor: row.bgColor}}>
    //         {row.value}
    //       </div>
    //     )
    //   }
    // },
    // { 
    //   title: (
    //     <div className="w-full h-[75px] flex items-center justify-center p-3 border-l border-gray-400">
    //       Actual Vs Target
    //     </div>
    //   ),
    //   width: 150,
    //   dataIndex: 'actual_vs_target', 
    //   key: 'actual_vs_target', 
    //   className: 'th-p-0 bordered-top font-medium text-right-force relative',
    //   render: (row, record) => {
    //     return (
    //       <div 
    //         className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
    //         style={{backgroundColor: row.bgColor}}>
    //         {row.value}
    //       </div>
    //     )
    //   }
    // },
    { 
      title: (
        <div className="w-full h-[75px] flex items-center justify-center p-3 border-l border-gray-400">
          Actual Vs BEP
        </div>
      ),
      width: 90,
      dataIndex: 'actual_vs_bep', 
      key: 'actual_vs_bep', 
      className: 'th-p-0 bordered-top-right font-medium text-right-force relative',
      render: (row, record) => {
        return (
          <div 
            className={`${record.className} ${row.className} absolute top-0 left-0 h-full flex items-center justify-end`} 
            style={{backgroundColor: row.bgColor}}>
            {row.value}
          </div>
        )
      }
    },
  ]

  const handleExpand = () => {
    let cloneListData = _.cloneDeep(originData) 
    const insertAt = (arr, index, newItem) => [
      ...arr.slice(0, index),
      ...newItem, ...arr.slice(index)
    ];

    Object.keys(childData).forEach(function(key, parentIndex) {
      for (let i = 0; i < cloneListData.length; i++) {
        const element = cloneListData[i];
        const parentType = element.type.split("-")[0]

        if(parentType === key) {
          if(!isExpand) {
            cloneListData = insertAt(cloneListData, i+1, childData[key])              
          } else {
            cloneListData = _.pullAllBy(cloneListData, childData[key], "key")
          }
          break
        }
      }
    });

    setIsExpand(!isExpand)
    setOriginData(cloneListData)  
  }

  // handle reset data
  const handleResetFilter = () => {
    setIsExpand(false)
    setOriginData([])
    setFilter({
      company_id: "",
      year: new Date(),
      plant_id: "",
      month: new Date().setMonth(0),
    })
  }

  return (
    <div className="h-full w-full flex flex-col">
      <h1 className="text-4xl mb-5 capitalize">Financial Statement</h1>
      <ContentHeader
        title="Employee Expense"
        onRefresh={() => getFinancialCalculation("sync")}
        onDownloadData={() => filter.plant_id === "" 
        ? toastHook.error("Table Cannot be Empty!") 
        : downloadFinancialStatementReport(filter)}
        customElement={
          <FilterMasterDataExpense 
            setDisabled={setLoadingTable}
            isDisabled={loadingTable}
            detailData={filter}
            onSave={handleFilterData}
            onReset={handleResetFilter}
          />
        }
      />
      <div className="w-full h-full flex-1 bg-white rounded-[16px] mt-4">
        <FinancialStatment1Table 
          tableColumns={columns}
          tableData={originData}
          isLoading={loadingTable}
        />
      </div>
    </div>
  )
}

export default FinancialStatment1;