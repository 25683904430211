import _ from "lodash";
import dayjs from "dayjs";
import config from "constant/config";

/**
 * [Cookies function] [Set, Get and get total days by range date]
 */

const setCookies = (name, value, days) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookies = (name) => {
  let cookiesName = name + "=";
  let splitCookies = document.cookie.split(";");
  for (let i = 0; i < splitCookies.length; i++) {
    let cookiesLoop = splitCookies[i];
    while (cookiesLoop.charAt(0) === " ") {
      cookiesLoop = cookiesLoop.substring(1, cookiesLoop.length);
    }
    if (cookiesLoop.indexOf(cookiesName) === 0) {
      return cookiesLoop.substring(cookiesName.length, cookiesLoop.length);
    }
  }
  return null;
};

const removeCookies = (name, path, domain) => {   
  if( getCookies( name ) ) {
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

const getDaysRange = (date1, date2) => {
  if (date1 && date2) {
    return Math.abs(dayjs(date2).diff(dayjs(date1), "days")) + 1;
  }
  return undefined;
};
/**
 * End of Cookies function
 */

 
/* Fungsi */
const formatRupiah = (
  prefix,
  value,
  rounding = 'FLOOR' // FLOOR, CEIL
) => {
    const usedValue = rounding === 'FLOOR' ? Math.floor(value) : Math.ceil(value);
    let number_string = usedValue.toString().replace(/[^,\d]/g, '').toString(),
        split     = number_string.split(','),
        countLeft = split[0].length % 3,
        rupiah    = split[0].substr(0, countLeft),
        thousand  = split[0].substr(countLeft).match(/\d{3}/gi);

    if (thousand) {
        let separator = countLeft ? '.' : '';
        rupiah += separator + thousand.join('.');
    }
    
    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
}

function b64toBlob(dataURI) {
    
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg', name: "filename" });
}

const htmlDecode = (content) => {
  let e = document.createElement('div');
  e.innerHTML = content;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

const formatPhone = (number) => {
  var str = number;
  str = str.replace(/\s/g, '')
  str = str.replace(/\D/g, '').slice(-Math.abs(str.length - 3))
  str = (str * 1).toString()
  var my_string = '' + str
  while (my_string.length < str.length+1) {
      my_string = '0' + my_string
  }
  return my_string
}

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}

const downloadFile = (dataurl, filename = "filename") => {
  console.log(config.ApiUrl + dataurl)
  const link = document.createElement("a");
  link.href = config.ApiUrl + dataurl;
  link.download = filename;
  link.click();
}

const objectToQueryString = (params) => {
  const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return `?${queryString}`
}

/*Handle filter*/
const handleFilter = (filters) => {
  let filterParams = {}
    for (const key in filters) {
      const element = filters[key];
      if(!_.isEmpty(element)) {
        if(_.isObject(element)) {
          filterParams[key] = element.value
        } else {
          filterParams[key] = element
        }
      } else if(_.isDate(element)) {
        let formatDate = dayjs(element).format("YYYY-MM-DD")
        filterParams["createdAt"] = `betweenDate:${formatDate},${formatDate}`
      } else if(_.isInteger(element)) {
        filterParams[key] = element
      } else if (_.isString(element)) {
        if(element !== "") {
          filterParams[key] = element
        }
      }
    }
  return filterParams
}

const handleEmptyObject = (object, objectName) => {
  if(object) {
    if(objectName) {
      return object[objectName]
    }
    return object
  }
  return "-"
}

export { setCookies, getCookies, removeCookies, getDaysRange, formatRupiah, b64toBlob, htmlDecode, handleFilter, formatPhone, DataURIToBlob, downloadFile, objectToQueryString, handleEmptyObject };

