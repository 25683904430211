const apiList = {
  sampleApi: "/sample/api",
  country: "/country",
  currency: "/currency",
  employeeType: "/client/provider",
  masterData: {
    country: "/country",
    branch: "/branch",
    kursUsd: "/exchange-rate",
    company: "/company",
    averagePrice: "/company-average-price",
    salesType: "/sales-type",
    salesData: "/sales-data",
    financialStatment: "/financial-statement",
    costAllocation: "/cost-allocation",
    salesmanType: "/salesman-type",
    employeeLevel: "/employee-level",
    personnel: "/personnel",
    assignPersonnel: "/assign-personnel",
    personnelNumber: "/personnel-number",
    targetSalesman: "/target-salesman",
    omsetContribution: "/omset-contribution"
  },
  warehouse: {
    warehouse1: "/warehouse-1",
    warehouse2: "/warehouse-2",
    warehouse3: "/warehouse-3",
    workingDays: "/working-day"
  },
  vehicle: {
    salesmanProportion: "/salesman-proportion",
    vehicleType: "/vehicle-type",
    vehicleMaster: "/vehicle-master"
  },
  expense: {
    employeeExpense: "/employee-expense-type",
    officerExpense: "/office-expense-type",
    utilityExpense: "/utility-expense-type",
    masterDataExpense: "/master-data-expense",
  },
  report: {
    warehouseCalculation: "/warehouse-calculation",
    salesmanCalculation: "/sales-calculation",
    vehicleCalculation: "/vehicle-calculation",
    financialCalculation: "/financial-calculation", 
  }
};

export default apiList;
