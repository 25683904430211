import apiList from "constant/apiUrl"
import Api from "helper/Api"
import toastHook from "helper/Hook/toastr"
 
export const getCountryList = (filter = {}) => {
	return Api().get(apiList.masterData.country, {
		params: {
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage
		}
	})
	.then(result => {
		const returnData = result.data.data
		const data = returnData.records.map(result => {
			return { 
				description: result.description, 
				id: result.id,
        country_id: result.country_id,
				country: result.country,
				company_id: result.company_id
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		return {data, pagging}
	})
	.catch(err => toastHook.error(err.message))
}

export const getCurrency = (filter = {}) => {
	return Api().get(apiList.currency, {
		params: {
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage
		}
	})
	.then(result => {
		const returnData = result.data.data
		const data = returnData.map(result => {
			return { 
				description: result.description, 
				currency_id: result.currency_id,
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		return {data, pagging}
	})
	.catch(err => toastHook.error(err.message))
}

export const getSalesType = (filter = {}) => {
	return Api().get(apiList.masterData.salesType, {
		params: {
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage,
			field: filter.fieldToFilter,
			filter: filter.filterFieldValue
		}
	})
	.then(result => {
		const returnData = result.data.data
		const data = returnData.records.map(result => {
			return { 
				sales_type: result.sales_type, 
				id: result.id,
				description: result.description,
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		return {data, pagging}
	})
	.catch(err => toastHook.error(err.message))
}

export const getBranchList = (filter = {}) => {
	return Api().get(apiList.masterData.branch, {
		params: {
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage,
			field: filter.fieldToFilter,
			filter: filter.filterFieldValue
		}
	})
	.then(result => {
		const returnData = result.data.data
		const data = returnData.records.map(result => {
			return { 
				company_id: result.company_id, 
				company_description: result.description, 
				id: result.id,
				key: result.id,
				plant_id: result.plant_id,
				description: result.description,
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		return {data, pagging}
	})
	.catch(err => toastHook.error(err.message))
}

export const getPersonnelList = (filter = {}) => {
	return Api().get(apiList.masterData.personnel, {
		params: {
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage,
			field: filter.fieldToFilter,
			filter: filter.filterFieldValue
		}
	})
	.then(result => {
		const returnData = result.data.data
		const data = returnData.records.map(result => {
			return { 
				company_id: result.company_id, 
				description: result.description, 
				personnel_id: result.personnel_id,
				price: result.price,
				id: result.id,
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		return {data, pagging}
	})
	.catch(err => toastHook.error(err.message))
}

export const getEmployeeLevelList = (filter = {}) => {
	return Api().get(apiList.masterData.employeeLevel, {
		params: {
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage,
			field: filter.fieldToFilter,
			filter: filter.filterFieldValue
		}
	})
	.then(result => {
		const returnData = result.data.data
		const data = returnData.records.map(result => {
			return { 
				company_id: result.company_id, 
				employee_type: result.employee_type, 
				employee_level: result.employee_level,
				id: result.id,
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		return {data, pagging}
	})
	.catch(err => toastHook.error(err.message))
}

export const getSalesmanTypeList = (filter = {}) => {
	return Api().get(apiList.masterData.salesmanType, {
		params: {
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage,
			field: filter.fieldToFilter,
			filter: filter.filterFieldValue
		}
	})
	.then(result => {
		const returnData = result.data.data
		const data = returnData.records.map(result => {
			return { 
				company_id: result.company_id, 
				salesman_type: result.salesman_type,
				id: result.id,
				description: result.description
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		return {data, pagging}
	})
	.catch(err => toastHook.error(err.message))
}

export const getWarehouse1 = (filter = {}) => {
	return Api().get(apiList.warehouse.warehouse1, {
		params: {
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage
		}
	})
	.then(result => {
		const returnData = result.data.data
		const data = returnData.records.map(result => {
			return { 
				type_warehouse: result.type_warehouse,
				id: result.id,
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		return {data, pagging}
	})
	.catch(err => toastHook.error(err.message))
}

export const getVehicleType = (filter = {}) => {
	return Api().get(apiList.vehicle.vehicleType, {
		params: {
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage,
			field: filter.fieldToFilter,
			filter: filter.filterFieldValue
		}
	})
	.then(result => {
		const returnData = result.data.data
		const data = returnData.records.map(result => {
			return { 
				vehicle_type: result.vehicle_type,
				description: result.description,
				id: result.id,
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		return {data, pagging}
	})
	.catch(err => toastHook.error(err.message))
}

export const getGeneralType = (filter = {}, type) => {
	return Api().get(apiList.employeeType, {
		params: {
			field: type,
			order: "updatedAt:desc",
			search: filter.search, 
			limit: filter.currentPerPage,
			page: filter.currentPage
		}
	})
	.then(result => {
		const returnData = result.data.data
		console.log(returnData)
		const data = returnData.records.map(result => {
			return { 
				code: result.code, 
				id: result.id,
				description: result.description
			}
		})
		const pagging = {
			pagging_data_detail: returnData.paggination,
			total_page: returnData.total_page,
			total_record: returnData.total_record
		}

		return {data, pagging}
	})
	.catch(err => toastHook.error(err.message))
}