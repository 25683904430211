import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routeUrl } from "constant";
import WarehouseCalculation from "page/reports/warehouseCalculation";
import FinancialStatment1 from "page/reports/financialStatment1";
import SalesmanCalculation from "page/reports/salesmanCalculation";
import VehicleCalculation from "page/reports/vehicleCalculation";

const Dashboard = lazy(() => import("page/dashboard"))
// Master Data
const Country = lazy(() => import("page/masterdata/country"))
const Branch = lazy(() => import("page/masterdata/branch"))
const KursUSD = lazy(() => import("page/masterdata/kursUSD"))
const AveragePrice = lazy(() => import("page/masterdata/averagePrice"))
const SalesType = lazy(() => import("page/masterdata/salesType"))
const SalesData = lazy(() => import("page/masterdata/salesData"))
const FinancialStatment = lazy(() => import("page/masterdata/financialStatment"))
const CostAllocation = lazy(() => import("page/masterdata/costAllocation"))
const SalesmanType = lazy(() => import("page/masterdata/salesmanType"))
const EmployeeLevel = lazy(() => import("page/masterdata/employeeLevel"))
const Personnel = lazy(() => import("page/masterdata/personnel"))
const AssignPersonnel = lazy(() => import("page/masterdata/assignPersonnel"))
const PersonnelNumber = lazy(() => import("page/masterdata/personnelNumber"))
const TargetSalesman = lazy(() => import("page/masterdata/targetSalesman"))
const OmsetContribution = lazy(() => import("page/masterdata/omsetContribution"))
// Warehouse
const Warehouse1 = lazy(() => import("page/warehouse/warehouse1"))
const Warehouse2 = lazy(() => import("page/warehouse/warehouse2"))
const Warehouse3 = lazy(() => import("page/warehouse/warehouse3"))
const WorkingDays = lazy(() => import("page/warehouse/workingDays"))
// Vehicle
const SalesmanPropotion = lazy(() => import("page/vehicle/salesmanProportion"))
const VehicleType = lazy(() => import("page/vehicle/vehicleType"))
const VehicleMaster = lazy(() => import("page/vehicle/vehicleMaster"))
// Expense
const EmployeeExpense = lazy(() => import("page/expense/employeeExpense"))
const OfficerExpense = lazy(() => import("page/expense/officerExpense"))
const UtilityExpense = lazy(() => import("page/expense/utilityExpense"))
const MasterDataExpense = lazy(() => import("page/expense/masterDataExpense"))


const Router = () => {
  return (
    <Suspense fallback={<div>Loading... </div>}>
      <Routes>
        <Route
          path={routeUrl.home}
          element={<Navigate to="/dashboard" replace />}
        />
        {/* <Route path={routeUrl.home} exact element={<HomePage/>} /> */}
        <Route path={routeUrl.dashboard} exact element={<div>Hello Dashboard</div>} />
        
        {/* Master data */}
        <Route path={routeUrl.masterDataCountry} exact element={<Country/>} />
        <Route path={routeUrl.masterDataBranch} exact element={<Branch/>} />
        <Route path={routeUrl.masterDataKurs} exact element={<KursUSD/>} />
        <Route path={routeUrl.masterDataAveragePrice} exact element={<AveragePrice/>} />
        <Route path={routeUrl.masterDataSalesType} exact element={<SalesType/>} />
        <Route path={routeUrl.masterDataSalesData} exact element={<SalesData/>} />
        <Route path={routeUrl.masterDataFinancialStatment} exact element={<FinancialStatment/>} />
        <Route path={routeUrl.masterDataCostAllocation} exact element={<CostAllocation/>} />
        <Route path={routeUrl.masterDataSalesmanType} exact element={<SalesmanType/>} />
        <Route path={routeUrl.masterDataEmployeeLevel} exact element={<EmployeeLevel/>} />
        <Route path={routeUrl.masterDataPersonnel} exact element={<Personnel/>} />
        <Route path={routeUrl.masterDataAssignPersonnel} exact element={<AssignPersonnel/>} />
        <Route path={routeUrl.masterDataPersonnelNumber} exact element={<PersonnelNumber/>} />
        <Route path={routeUrl.masterDataTargetSalesman} exact element={<TargetSalesman/>} />
        <Route path={routeUrl.masterDataOmsetContribution} exact element={<OmsetContribution/>} />
        <Route path={routeUrl.masterDataRo} exact element={<Dashboard/>} />
        <Route path={routeUrl.masterDataRealisasiRp} exact element={<Dashboard/>} />

        {/* Warehouse */}
        <Route path={routeUrl.warehouse1} exact element={<Warehouse1/>} />
        <Route path={routeUrl.warehouse2} exact element={<Warehouse2/>} />
        <Route path={routeUrl.warehouse3} exact element={<Warehouse3/>} />
        <Route path={routeUrl.warehouseWorkingDays} exact element={<WorkingDays/>} />

        {/* Vehicle */}
        <Route path={routeUrl.salesmanProportion} exact element={<SalesmanPropotion/>} />
        <Route path={routeUrl.vehicleType} exact element={<VehicleType/>} />
        <Route path={routeUrl.vehicleMaster} exact element={<VehicleMaster/>} />

        {/* Expense */}
        <Route path={routeUrl.employeeExpense} exact element={<EmployeeExpense/>} />
        <Route path={routeUrl.officerExpense} exact element={<OfficerExpense/>} />
        <Route path={routeUrl.utilityExpense} exact element={<UtilityExpense/>} />
        <Route path={routeUrl.masterDataExpense} exact element={<MasterDataExpense/>} />

        {/* Reports */}
        <Route path={routeUrl.reportsWarehouseCalculation} exact element={<WarehouseCalculation/>} />
        <Route path={routeUrl.reportsFinancialStatment1} exact element={<FinancialStatment1/>} />
        <Route path={routeUrl.reportSalesmanCalculation} exact element={<SalesmanCalculation/>} />
        <Route path={routeUrl.reportVehicleCalculation} exact element={<VehicleCalculation/>} />

      </Routes>
    </Suspense>     
  )
}
 
export default Router