/* eslint-disable react-hooks/exhaustive-deps */
import { IconList } from "assets/image";
import BaseButton from "components/Atoms/Button/basicButton";
import BaseDropdown from "components/Atoms/Dropdown/baseDropdown";
import InputSearch from "components/Atoms/Input/inputSearch";
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { useState } from "react";

const ContentHeader = ({
    title, 
    rowSelected, 
    onSearchCallback, 
    onClickCreate, 
    onUploadClick, 
    onDeleteClick,
    onRefresh,
    onDownloadData,
    onClickFilter,
    onDwonloadTemplate,
    customElement
}) => {
  const [saearchValue, setSaearchValue] = useState("")
  const [timerID, setTimerID] = useState(null);
  const itemsPerpage = [
    {
      id: 1,
      type: "button",
      label: "Download Template",
      icon: IconList.DownloadIcon,
      onClick: () => onDwonloadTemplate()
    },
    {
      id: 2,
      type: "button",
      label: "Download Data",
      icon: IconList.DownloadIcon,
      onClick: () => onDownloadData()
    },
    {
      id: 3,
      type: "button",
      label: "Upload Template",
      icon: IconList.UploadTemplateIcon,
      onClick: () => onUploadClick()
    },
    {
      id: 4,
      type: "button",
      label: "Sync Data",
      icon: IconList.SyncIcon,
      onClick: () => onRefresh()
    }
  ]
  
  const handleFilterItemsPerpage = () => {
    const cloneItems = _.cloneDeep(itemsPerpage)
    const removeArrayById = (array, id) => {
      return array.some((o, i, a) => o.id === id
          ? a.splice(i, 1)
          : removeArrayById(o.sub || [], id)
      );
    }

    if(!onDwonloadTemplate) {
      removeArrayById(cloneItems, 1)
    } 
    if(!onDownloadData) {
      removeArrayById(cloneItems, 2)
    } 
    if(!onUploadClick) {
      removeArrayById(cloneItems, 3)
    } 
    if(!onRefresh) {
      removeArrayById(cloneItems, 4)
    }
    return cloneItems
  }

  const handleSearch = (event) => {
    setSaearchValue(event.target.value)

    clearTimeout(timerID);
    const timer = setTimeout(() => onSearchCallback(event.target.value), 1000);
    setTimerID(timer)
  }

  return (
    <div className="bg-white rounded-[16px] w-full flex flex-row px-5 py-4 min-h-[80px] items-center">
      <div className="flex flex-1 items-center">
        {rowSelected !== 0 
          ? <span>{rowSelected} Document {title} are Selected</span>
          : onSearchCallback && <div className="w-[460px]">
            <InputSearch 
              value={saearchValue} 
              onChange={handleSearch} /> 
          </div>
        }
        {onClickFilter && (
          <div className="flex flex-row items-center ml-2">
            <span className="px-2">Filter</span>
            <BaseButton 
              icon={IconList.ChevronDownGray}
              addClasses="px-1 py-2 bg-white border-[1px] border-gray-500 text-black rounded-full w-[170px] flex justify-between items-center px-3" 
              title="Select" 
              type="outlined" 
              onClick={onClickFilter} 
            />
          </div>
        )}
        {customElement && customElement}
      </div>

      <div className="flex flex-row-reverse gap-x-4 h-[48px]">
        {rowSelected !== 0 
          ? <BaseButton addClasses="text-red-500 border-red-500" title="Delete" type="outlined" onClick={onDeleteClick} />
          : (
              <>
                {onClickCreate && <BaseButton title="Create" type="filled" onClick={onClickCreate} />}
                <BaseDropdown title={<span className="text-[16px]">More</span>} dropdownContent={handleFilterItemsPerpage()} icon={IconList.ChevronDownPurple} />
              </>
            ) 
        }
      </div>
    </div>
  )
}

ContentHeader.propTypes = {
  onClickCreate: PropTypes.func,
  onUploadClick: PropTypes.func,
  onClickDelete: PropTypes.func,
  onDownloadData: PropTypes.func,
  onRefresh: PropTypes.func,
  rowSelected: PropTypes.number,
  title: PropTypes.string,
  customElement: PropTypes.string
};

ContentHeader.defaultProps = {
  onClickCreate: null,
  onClickFilter: null,
  onUploadClick: null,
  onClickDelete: null,
  onDownloadData: null,
  onRefresh: null,
  rowSelected: 0,
  title: "",
  customElement: ""
};


export default ContentHeader