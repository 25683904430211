import apiList from 'constant/apiUrl';
import Api from 'helper/Api';
import { downloadFile, objectToQueryString } from 'helper/functions';
import toastHook from 'helper/Hook/toastr';
import _ from 'lodash';
import { assignBaseChildData, assignBaseData, assignFlaggingData, assignTkoChildDataMidData, assignTkoDataMidData, assignTspcMidData } from './dataHelper';

// Get list
export const getListVehicleCalculation = (filter, type) => {
	return Api().get(apiList.report.vehicleCalculation, {
		params: filter
		// {
		// 	// order: "updatedAt:desc",
		// 	company_id: "EM00", 
		// 	year: "2021",
		// 	plant_id: "BW04",
		// }
	})
	.then(result => {
		const returnData = result.data
		const {data, month} = returnData
		let mainData = []
		let childData = []

		if(!_.isEmpty(data)) {
			Object.keys(data).forEach((key, parentIndex) => {
				if(data.hasOwnProperty(key)) {
					data[key].forEach((item, childIndex) => {
						if(childIndex === 0) {
							childData[key] = []
							if(item.description) {
								mainData.push(assignBaseData(item, `${key}-${parentIndex}`, false))
							} else {
								data[key].forEach((item2, parentIndex2) => {
									const tkoData = item2.total_taking_order
									const tspcData = item2.total_salesman_proportion_calculation
									const pmData = item2.plus_minus
									const pmwbdData = item2.plus_minus_by_working_days

									mainData.push(assignFlaggingData(pmwbdData,`flagData${parentIndex2}-${parentIndex}`))
									mainData.push(assignTkoDataMidData(tkoData, key, `tkoData-${parentIndex2}-${parentIndex}`))
									mainData.push(assignTspcMidData(tspcData, key, `tspcData-${parentIndex2}-${parentIndex}`))
									mainData.push(assignBaseData(pmData,`pmData-${parentIndex2}-${parentIndex}`))
									mainData.push(assignBaseData(pmwbdData,`pmwbdData${parentIndex2}-${parentIndex}`))

									const childKey = `flagData${parentIndex2}`
									childData[childKey] = []
									item2.vehicles.forEach((vehicle, vehicleIndex) => {
										childData[childKey]
										.push(assignTkoChildDataMidData(vehicle, key, `flagData${parentIndex2}-${parentIndex}-${vehicleIndex}`))
									});
								})
							}
						} else {
							if(item.description) {
								if(key === "summary") {
									mainData.push(assignBaseData(item, `${key}-${childIndex}`, false))	
								} else {
									childData[key].push(assignBaseData(item, `${key}-${childIndex}`, false))
								}
							} else {
								if(item.tgrit1) {
									childData[key].push(assignBaseChildData(item, `${key}-${childIndex}`))
								} 
								// else {
								// 	item.vehicles.forEach((vehicle, vehicleIndex) => {
								// 		childData[key]
								// 		.push(assignTkoChildDataMidData(vehicle, key, `${key}-${vehicleIndex}-${childIndex}`))
								// 	});
								// }
							}
						}
					})
				}
			});
		} else {
			toastHook.error("Oops! data is empty!")
		}
		
		// console.log(mainData)
		// console.log(childData)
		if(type === "sync") {
			toastHook.success("Sync data successfully!")
		}
		return {mainData, childData, month}

	})
	.catch(err => {
		if(type === "sync") {
			toastHook.error("Oops! unable to sync data. try again")
		} else {
			toastHook.error(err.message)
		}
		return err
	})
}

export const downloadVehicleCalculation = (filter) => {
	return downloadFile(apiList.report.vehicleCalculation+`/export${objectToQueryString(filter)}`, "vehicle_calculation")
}